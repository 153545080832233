import { Component, NgZone, Input, Output, ViewChild, ElementRef, EventEmitter } from '@angular/core';

import { MapsAPILoader, MouseEvent } from '@agm/core';

import { MARKER_LIST_MODEL } from './MARKER_LIST_MODEL.model';

import * as _ from 'lodash';

declare var window: any;

@Component({
    selector: 'ac-custom-maps',
    templateUrl: './ac-custom-maps.component.html',
    styleUrls: ['./ac-custom-maps.component.css']
})
export class CustomMapsAC {
    @Input() isAdressSearchBox: boolean = false;
    @Input() latitude: number = 0.0;
    @Input() longitude: number = 0.0;
    @Input() isLocationVisible: boolean = false;
    @Input() isMarker: boolean = false;
    @Input() isMarkerCircle: boolean = false;
    @Input() isHeatmap: boolean = false;
    @Input() isInfoWindow: boolean = false;
    @Input() circleRadius: number = 100;
    @Input() isMarkerPositionChange: boolean = false;
    @Input() markerIconURL: string = '';
    @Input() gestureHandling: "cooperative" | "greedy" | "none" | "auto" = 'auto';
    @Input() scrollwheel: boolean = true;
    @Input() zoom: number;
    @Input() maxZoom: number;
    @Input() isAutoFitBound: boolean = false;
    @Input() listPolyline: Array<{ latitude: number, longitude: number, label?: string }> = new Array();
    @Input() listMarkers: Array<MARKER_LIST_MODEL> = new Array();
    @Input() listHeatMapData: Array<{ latitude: number, longitude: number}> = new Array();
    @Input() flag: number;

    @Output() onChangePosition: EventEmitter<any> = new EventEmitter<any>();
    @Output() onChangeCircleRadius: EventEmitter<number> = new EventEmitter<number>();
    @Output() onMapReady: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild("search") searchElementRef: ElementRef;
    @ViewChild('AC_CUSTOM_MAP') AC_CUSTOM_MAP: any;

    isMapReady: boolean = false;
    private isShowingDashboardFirstTime: boolean = true;

    private googleMap: google.maps.Map = null;

    constructor(
        private mapsAPILoader: MapsAPILoader,
        private ngZone: NgZone
    ) { }

    ngOnInit() { }

    ngOnChanges() {
        this.fitBound();
    }

    ngAfterViewInit() {
        this.confSearchBox();
    }

    private confSearchBox() {
        if (!this.isAdressSearchBox || !this.searchElementRef)
            return;


        this.mapsAPILoader.load().then(() => {
            let autocomplete = new window.google.maps.places.SearchBox(this.searchElementRef.nativeElement, {
                types: ["address"]
            });
            autocomplete.addListener("places_changed", () => {
                this.ngZone.run(() => {
                    //get the place result
                    let place: any[] = autocomplete.getPlaces();

                    //verify result
                    if (place.length <= 0) {
                        return;
                    }

                    //set latitude, longitude and zoom
                    this.changeLatLong({
                        coords: {
                            lat: place[0].geometry.location.lat(),
                            lng: place[0].geometry.location.lng()
                        }
                    });
                });
            });
        });
    }


    mapClicked($event: MouseEvent) { this.changeLatLong($event); }
    markerDragEnd(m: any, $event: MouseEvent) { this.changeLatLong($event); }
    changeLatLong($event: MouseEvent) {
        if (!this.isMarkerPositionChange)
            return;

        this.latitude = $event.coords.lat;
        this.longitude = $event.coords.lng;

        this.onChangePosition.emit({
            latitude: this.latitude,
            longitude: this.longitude
        })
    }

    onChangeRadius(event) {
        this.onChangeCircleRadius.emit(Number.parseInt(event))
    }

    onChangeCenter(event) {
        this.onChangePosition.emit({
            latitude: event.lat,
            longitude: event.lng
        })
    }

    mapReady(event) {
        //console.log('Map ready');
        this.isMapReady = true;
        this.onMapReady.emit(event);

        setTimeout(() => this.isHeatmap ? this.prepareHeatMap(event) : this.fitBound(), 250);
    }

    prepareHeatMap(mapInstance: google.maps.Map) {

        let bounds: google.maps.LatLngBounds = new window.google.maps.LatLngBounds();

        this.googleMap = mapInstance;

        const coords: google.maps.LatLng[] = this.listHeatMapData.map(heatMapData => {

            const lat = heatMapData.latitude;
            const lng = heatMapData.longitude;

            bounds.extend(new google.maps.LatLng(lat, lng));

            return new google.maps.LatLng(lat, lng);
        });


        this.googleMap.fitBounds(bounds);
    }

    fitBound() {
        if (!this.isAutoFitBound || !this.isMapReady)
            return;

        let bounds: google.maps.LatLngBounds = new window.google.maps.LatLngBounds();

        //TRACKER REALTIME
        if(this.isMarker) {

            if(this.latitude && this.longitude && this.latitude !== 0 && this.longitude !== 0) {

                bounds.extend(new google.maps.LatLng(this.latitude, this.longitude));

                this.AC_CUSTOM_MAP._updateBounds(bounds);

                this.zoom = 15;
            }
            else {
                this.zoom = 1;
            }
        }
        else {

            if((this.listPolyline && this.listPolyline.length > 0) || (this.listMarkers && this.listMarkers.length > 0)) {
                //TRACKER HISTORICAL
                if(this.listPolyline.length > 0) {
                    this.listPolyline
                        .filter(k => k.latitude && k.longitude)
                        .forEach(k => bounds.extend(new google.maps.LatLng(k.latitude, k.longitude)));
                }

                //DASHBOARD
                if(this.listMarkers.length > 0) {

                    const listMarkersWithLocations = this.listMarkers.filter(k => k.latitude != 0 && k.longitude != 0);

                    const listMarkersGroup = _.groupBy(listMarkersWithLocations, x => `${x.latitude}${x.longitude}`);

                    const groupKeys = Object.keys(listMarkersGroup);

                    for(const key of groupKeys) {
                        const arr = listMarkersGroup[key];
                        if(arr.length > 1) {
                            for(let i = arr.length - 1; i > 0; i--) {
                                let index = _.findIndex(listMarkersWithLocations, a => (a.latitude === arr[i].latitude) && (a.longitude === arr[i].longitude));
                                listMarkersWithLocations[index].latitude += (Math.random() -.5) / 2500;// * (Math.random() * (max - min) + min);
                                listMarkersWithLocations[index].longitude += (Math.random() -.5) / 2500;// * (Math.random() * (max - min) + min);
                            }
                        }
                    }

                    listMarkersWithLocations.forEach(k => bounds.extend(new google.maps.LatLng(k.latitude, k.longitude)));
                }
            
                if(this.isShowingDashboardFirstTime) {
                    this.AC_CUSTOM_MAP._updateBounds(bounds);
                    this.isShowingDashboardFirstTime = false;
                }
            }
            else {
                this.zoom = 1;
            }
        }
    }

    clickOnListMarkers(marker: MARKER_LIST_MODEL) {
        if (!marker.callbackMarkerClick)
            return;

        marker.callbackMarkerClick(marker);
    }

    // setCenter(latitude, longitude){
    //     if(!this.isMapReady)
    //         return;

    //     this.latitude = latitude;
    //     this.longitude = longitude;
    //     this.AC_CUSTOM_MAP['_setCenter']();
    // }
}
