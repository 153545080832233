import { Component, Output, EventEmitter, Input } from '@angular/core';

import {listener_SEARCHBOX} from './searchbox.listener';

@Component({
    selector: 'search-box',
    templateUrl: './search-box.component.html',
    styleUrls: ['./search-box.component.css']
})
export class SearchBox {
    @Input() listenerKey: String = '';

    @Output() onTextChanges: EventEmitter<any> = new EventEmitter<'' | string>();
    @Output() onKeyEnter: EventEmitter<null | '' | string> = new EventEmitter<null | '' | string>();

    public searchText: String = '';

    onKeydown(event) {
        if (event.key === "Enter") {
            this.onKeyEnter.emit();
        }
    }

    change() {
        this.onTextChanges.emit(this.searchText);
        listener_SEARCHBOX.emit({
            key: this.listenerKey,
            text: this.searchText
        });
    }
}