import { CLIENT_TYPES } from 'src/app/authorization/authentication.service';
import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { AuthenticationService } from '../../../authorization/authentication.service';

@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LeftMenuComponent implements OnInit {
  clientype;
  constructor(private cdRef: ChangeDetectorRef, private authenticationService: AuthenticationService) { }

  ngOnInit() {
     this.clientype = this.authenticationService.currentUserValue.clientType;
    setTimeout(() => { this.cdRef.markForCheck(); }, 1000);
    window.addEventListener('resize', () => {
      this.cdRef.markForCheck();
    });
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngAfterViewInit() {
    // tslint:disable-next-line:max-line-length
    if (this.authenticationService.currentUserValue.clientType !== CLIENT_TYPES.ALL_ACCESS && this.authenticationService.currentUserValue.clientType !== CLIENT_TYPES.SHIPMENT) {
      document.getElementById('analyticsSubmenu').remove();
    }
  }
}
