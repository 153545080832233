import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

import { Observable } from 'rxjs';

import { ShipmentService } from './shipment.service';

@Injectable({
    providedIn: 'root'
})
export class ShipmentsResolver implements Resolve<any[]> {

    constructor(private serviceShipment: ShipmentService) { }

    resolve(): Observable<any[]> {
        return this.serviceShipment.listShipments();
    }
}

@Injectable({
    providedIn: 'root'
})
export class ShipmentResolver implements Resolve<any> {

    constructor(private serviceShipment: ShipmentService) { }

    resolve(route: ActivatedRouteSnapshot ): Observable<any> {
        const shipmentId = route.paramMap.get('shipmentId');
        if (isNaN(+shipmentId)) {
            const message = `shipmentId was not a number: ${shipmentId}`;
            console.error(message);
            return Observable.throw(message);
        }

        return this.serviceShipment.showShipment(+shipmentId);
    }
}