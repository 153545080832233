import {Injectable} from '@angular/core';
import {Resolve, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';

import {Observable, of} from 'rxjs';
import {map, catchError} from 'rxjs/operators';

import {Beacon} from '../models/beacon';
import {BeaconsService} from './beacon.service';

@Injectable({
  providedIn: 'root'
})
export class BeaconsResolver implements Resolve<Beacon[]> {

  constructor(private beaconService: BeaconsService) {
  }

  resolve(route: ActivatedRouteSnapshot,
          state: RouterStateSnapshot): Observable<Beacon[]> {

    return this.beaconService.getBeacons()
      .pipe(
        map(beacons => {
          if (beacons) {
            return beacons;
          }
        }),
        catchError(error => {
          const message = `Retrieval error: ${error}`;
          console.error(message);
          return Observable.throw(error);
        })
      );
  }

}

@Injectable({
  providedIn: 'root'
})
export class BeaconResolver implements Resolve<Beacon> {

  constructor(private beaconService: BeaconsService) {
  }

  resolve(route: ActivatedRouteSnapshot,
          state: RouterStateSnapshot): Observable<Beacon> {

    const id = route.paramMap.get('beaconId');

    if (isNaN(+id)) {
      const message = `Beacon id was not a number: ${id}`;
      console.error(message);
      return Observable.throw(message);
    }

    return this.beaconService.getBeacon(+id)
      .pipe(
        map(beacon => {
          if (beacon) {
            return beacon;
          }
        }),
        catchError(error => {
          const message = `Retrieval error: ${error}`;
          console.error(message);
          return Observable.throw(error);
        })
      );
  }
}
