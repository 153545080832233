import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthenticationService, USER_TYPES } from '../../authorization/authentication.service';
import { ACToasterService } from '../../shared/services/ac-toaster.service';
import { UserService } from 'src/app/shared/services/user.service';
import {TranslateService} from "@ngx-translate/core";

const _headers: any[] = [
    { key: 'id', label: '/ID/', isSortable: false, isSearchable: false },
    { key: 'userName', label: 'FULL NAME', isSortable: true, isSearchable: true },
    { key: 'email', label: 'EMAIL', isSortable: true, isSearchable: true },
    { key: 'roleDescriptions', label: 'ROLE', isSortable: true, isSearchable: true },
    { key: 'activated', label: 'ACTIVE', isSortable: true, isSearchable: false },
    { key: '', label: 'ACTIONS', isSortable: false, isSearchable: false },
];

@Component({
    selector: 'overview-users',
    templateUrl: './overview-users.component.html',
    styleUrls: ['./overview-users.component.css']
})
export class OverviewUsers {
    isSuperAdmin: boolean = false;

    clientName: String;

    tableHeaders: any[] = _headers;
    users: any[] = [];
    isChecked: boolean = true;
    clientId: number;
    offset: number;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private serviceAuth: AuthenticationService,
        private serviceACToaster: ACToasterService,
        private serviceUser: UserService,
        private translateService: TranslateService
    ) { }

  async ngOnInit() {
        const { userTypes } = this.serviceAuth.currentUserValue;
        this.isSuperAdmin = userTypes.includes(USER_TYPES.SUPER_ADMIN);
        this.users = this.route.snapshot.data['users'];
        this.clientName = this.route.snapshot.params['clientName'];

        if (this.isSuperAdmin) { this.onChanges(); } else {
            this.users = await this.serviceUser.getUsers(0).toPromise();
            this.prepareUser();
        }
        let id = this.translateService.instant('/ID/');
        let fullName = this.translateService.instant('FULL NAME');
        let email = this.translateService.instant('EMAIL');
        let role = this.translateService.instant('ROLE');
        let active = this.translateService.instant('ACTIVE');
        let actions = this.translateService.instant('ACTIONS');
        this.tableHeaders = [
        { key: 'id', label: id, isSortable: false, isSearchable: false },
        { key: 'userName', label: fullName, isSortable: true, isSearchable: true },
        { key: 'email', label: email, isSortable: true, isSearchable: true },
        { key: 'roleDescriptions', label: role, isSortable: true, isSearchable: true },
        { key: 'activated', label: active, isSortable: true, isSearchable: false },
        { key: '', label: actions, isSortable: false, isSearchable: false },
      ];
    }

    prepareUser() {
        const result = this.users
            .map(user => {
                user.userName = `${user.name} ${user.last_name}`;

                user.roleDescriptions = (user.roles && user.roles.length > 0) ? user.roles.map(ur => ur.description).join(', ') : null;

                user.isNotEditable = ((user.roles && user.roles.length > 0) && !this.isSuperAdmin && user.roles.findIndex(ur => ur.name === USER_TYPES.SUPER_ADMIN) >= 0);

                user.isNotDelete = user.activated == 0 || ((user.roles && user.roles.length > 0) && !this.isSuperAdmin && user.roles.findIndex(ur => ur.name === USER_TYPES.SUPER_ADMIN) >= 0);

                return user;
            });
        this.users = JSON.parse(JSON.stringify(result));
    }

    onRemove(user) {
        const type = 'user';
        this.serviceACToaster.showRemoveConfirmToaster(type, user.name, async () => {
            try {
                await this.serviceUser.deleteUser(user).toPromise();
                user.activated = 0;
                user.roles = null;

                this.prepareUser();
            } catch (error) {
                this.serviceACToaster.showRemoveWarningToaster(error.message);
            }
        })
    }

    navigateToAddNewUser() {
        this.router.navigate(['users', 0])
    }
    async onChanges() {
        if (!this.isChecked) {
           this.users =  await this.serviceUser.getUsers(0).toPromise();
        } else {
            // @ts-ignore
            this.clientId = this.serviceAuth.currentUser.source.value.clientId;
             this.users  =  await this.serviceUser.getUsers_(this.clientId).toPromise();
            }
            this.prepareUser();
      }
}
