import { Observable } from 'rxjs';

export const SIGNED_URL_UPLOAD_TYPES = {
    NEW: 'NEW',
    WAITING: 'WAITING',
    SENDING: 'SENDING',
    UPLOADED: 'UPLOADED',
    ERROR: 'ERROR',
    COMPLETED: 'COMPLETED',
    PAUSED: 'PAUSED'
}

export class FileUploadWithSignedURL {
    private xmlHttpRequest: XMLHttpRequest;

    isUploading = false;
    uploadStatusCode: string = SIGNED_URL_UPLOAD_TYPES.NEW;
    progress: number = 0;

    constructor(private file, private signedURL: string) { }

    private setUploadState(status) {
        this.isUploading = status;
    }
    private setUploadingFile() {
        this.setUploadState(true);
    }
    private setNotUploadingFile() {
        this.setUploadState(false);
    }

    setUploadStatusCode(status: string) {
        this.uploadStatusCode = status;
    }
    setUploadStatusCode_and_Progress(progress: number, status: string) {
        this.setUploadStatusCode(status);
        this.progress = progress;
    }

    public abourtUploading() {
        this.setUploadState(true);
        if (this.xmlHttpRequest)
            this.xmlHttpRequest.abort();
    }

    private doFileUploadSignedURL(): Observable<null> {
        return Observable.create(observer => {
            this.setUploadStatusCode(SIGNED_URL_UPLOAD_TYPES.WAITING);
            this.setUploadingFile();

            this.xmlHttpRequest = new XMLHttpRequest();
            var xhr = this.xmlHttpRequest;
            if (!('withCredentials' in xhr)) {
                this.setUploadStatusCode(SIGNED_URL_UPLOAD_TYPES.ERROR);
                observer.error();
                return;
            }

            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200 || xhr.status === 201) {
                        console.log("File Upload ENDED");
                        this.setUploadStatusCode(SIGNED_URL_UPLOAD_TYPES.UPLOADED);
                        observer.next();
                        observer.complete();
                    } else {
                        console.log("File Upload ERROR");
                        this.setUploadStatusCode(SIGNED_URL_UPLOAD_TYPES.ERROR);
                        observer.error();
                    }
                }
            };

            xhr.upload.onprogress = (event) => {
                this.setUploadStatusCode_and_Progress(
                    Math.round(event.loaded / event.total * 100),
                    SIGNED_URL_UPLOAD_TYPES.SENDING
                );

                observer.next();
            };

            xhr.upload.onerror = (event) => {
                console.log("File Upload ERROR");
                xhr.abort();
                this.setUploadStatusCode(SIGNED_URL_UPLOAD_TYPES.ERROR);
                observer.next();
            };

            xhr.upload.ontimeout = (event) => {
                console.log("File Upload TimeOut");
                xhr.abort();
                this.setUploadStatusCode(SIGNED_URL_UPLOAD_TYPES.ERROR);
                observer.next();
            };

            xhr.open('PUT', this.signedURL, true);
            xhr.setRequestHeader('Content-Type', this.file.type);
            xhr.send(this.file);
        });
    }

    public doFileUpload(): Observable<any> {
        return Observable.create(observer => {
            this.doFileUploadSignedURL().subscribe(res => {
                if (this.uploadStatusCode != SIGNED_URL_UPLOAD_TYPES.UPLOADED) {
                    return;
                }

                observer.next();
                observer.complete();
            })
        })
    }
}