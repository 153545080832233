import { Directive, Injector, ElementRef, TemplateRef, ViewContainerRef } from '@angular/core';

import { ClientPermissionDirectivesBase } from './base-client';

import { CLIENT_TYPES } from 'src/app/authorization/authentication.service';

@Directive({
    selector: '[SHIPMENT]'
})
export class DirectiveShipmentDirectiveAC extends ClientPermissionDirectivesBase {

    constructor(
        injector: Injector,
        element: ElementRef,
        templateRef: TemplateRef<any>,
        viewContainer: ViewContainerRef
    ) {
        super(injector, element, templateRef, viewContainer, CLIENT_TYPES.SHIPMENT);
    }
}