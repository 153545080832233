import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from './api.base.service';

@Injectable({ providedIn: 'root' })
export class OrderService {
    private ordersURL = 'orders';

    constructor(private api: ApiService) {
    }
 
    listOrders(): Observable<any[]> {
        return this.api.httpGET<any[]>(this.ordersURL, 'orders');
    }

    showOrder(orderId): Observable<any> {
        return this.api.httpGET<any>(`${this.ordersURL}/${orderId}`, 'order');
    }
}