import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { Observable } from 'rxjs';

import { ProfileService } from './profile.service';

@Injectable({
    providedIn: 'root'
})
export class ProfileResolver implements Resolve<any> {

    constructor(private serviceProfile: ProfileService) { }

    resolve(): Observable<any> {
        return this.serviceProfile.getMyProfile();
    }
}