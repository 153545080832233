import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../authorization/authentication.service';

import { SubscribeBase } from '../../bases/subscribe.base';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent extends SubscribeBase implements OnInit {

  username: string;
  firstname: string;
  lastname: string;
  clientName: string;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
    super();
    this.addSubscribe('USER_INFO', this.authenticationService.currentUser, () => this.getUserInfo());
  }

  ngOnInit() {
    this.getUserInfo();
  }

  ngOnDestroy() {
    this.allitemsUnsubscribeAndRemove();
  }

  getUserInfo() {
    this.username = this.authenticationService.currentUserValue ? this.authenticationService.currentUserValue.username : "";
    this.firstname = this.authenticationService.currentUserValue ? this.authenticationService.currentUserValue.firstname : "";
    this.lastname = this.authenticationService.currentUserValue ? this.authenticationService.currentUserValue.lastname : "";
    this.clientName = this.authenticationService.currentUserValue ? this.authenticationService.currentUserValue.clientName : "";
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(["/login"]);
  }

  openAPIDocumentation() {
    window.open("https://documenter.getpostman.com/view/13392762/TVejhA9c", "_blank");
  }
}
