export const convertBatteryLevelToPercentageWithIconFNC = (value): string => {
    let fullStr = '';

    if(!value) {
        return 'N/A';
    }

    value = Math.round(value * 100);

    if(value === 102) {
      const fullyChargedText = 'Fully Charged';
        fullStr = fullyChargedText;
    }
    else if(value === 101) {
      const chargingText = 'Charging';
        fullStr = chargingText;
    }
    else if(value > 75 && value <= 100) {
        fullStr = `<span class="text-success"><i class="fa fa-battery-full"></i> ${value}%</span>`;
    }
    else if(value > 50 && value <= 75) {
        fullStr = `<span class="text-primary"><i class="fa fa-battery-three-quarters"></i> ${value}%</span>`;
    }
    else if(value > 20 && value <= 50) {
        fullStr = `<span class="text-primary"><i class="fa fa-battery-half"></i> ${value}%</span>`;
    }
    else if(value > 0 && value <= 20) {
        fullStr = `<span class="text-warning"><i class="fa fa-battery-quarter"></i> ${value}%</span>`;
    }
    else if (value === 0) {
        fullStr = `<span class="text-danger"><i class="fa fa-battery-empty"></i> ${value}%</span>`;
    }

    return fullStr;
}
