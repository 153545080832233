import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

declare var window: any;

@Component({
    selector: 'ac-tab-route',
    templateUrl: './ac-tab-route.component.html',
    styleUrls: ['./ac-tab-route.component.css']
})
export class TabRouteAC implements OnInit, OnChanges {
    @Input() list: { label: 'Notification History', routerLink: 'histories' }[] = [];

    private preLink='';

    constructor(private router: Router,
        private _Location: Location) { }

    ngOnInit() { this.init() }
    ngOnChanges() { this.init() }

    init() {
        const pathName:string = window.location.pathname;
        if(this.list.length<=0)
            return;

        const result = this.list.find(r=>pathName.endsWith(`/${r.routerLink}`));
        if(result)
            this.preLink = pathName.replace(pathName.substring(pathName.lastIndexOf(`/${result.routerLink}`), pathName.length), '')
    }
}
