import { Component } from '@angular/core';
import { ACToasterService } from '../../services/ac-toaster.service';

@Component({
    selector: 'ac-alarms-dd',
    templateUrl: './alarms-dropdown.component.html',
    styleUrls: ['./alarms-dropdown.component.css']
})
export class ACAlarmDropdown {
    dropdownContainerMaxHeight: number = 0;
    isOpen: boolean = false;

    constructor(
        public serviceACToaster: ACToasterService
    ) { }

    ngOnInit(){
        this.calculateDropdownMaxHeight();
        window.addEventListener('resize', this.calculateDropdownMaxHeight);
    }

    ngOnDestroy() {
        window.removeEventListener('resize', this.calculateDropdownMaxHeight);
    }

    onDropdown() {
        setTimeout(() => { this.isOpen = !this.isOpen; }, 555);
    }

    private calculateDropdownMaxHeight = () => {
        this.dropdownContainerMaxHeight = document.body.offsetHeight - 125;
    }

    onClearAlarms(){
        this.serviceACToaster.clearSeesionAlarms();
    }
}