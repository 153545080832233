import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

import { InputTextModule } from 'primeng/inputtext';
import { CheckboxModule } from 'primeng/checkbox';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { MessageService } from 'primeng/api';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TabViewModule } from 'primeng/tabview';

import { SharedModule } from '../shared/shared.module';
import { AuthGuard } from '../authorization/auth.guard';
import { GuardADMIN_P, GuardSUPER_ADMIN_P, Guard_SHIPMENT_P, Guard_ALL_ACCESS_P } from '../shared/guards/permissions';

import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { OverviewUsers } from './overview-users/overview-users.component';
import { EditUser } from './edit-user/edit-user.component';
import { EditProfile } from './edit-profile/edit-profile.component';
import { AssociationToolComponent } from '../trackers/association-tool/association-tool.component';

import { RegistrationService } from './register/register.service';
import { UsersResolver, UserResolver, ClientUsersResolver } from '../shared/services/users-resolvers.service';
import { ProjectsResolver } from '../shared/services/project-resolver.service';
import { ProfileResolver } from '../shared/services/profile-resolver.service';
import { TranslateModule } from "@ngx-translate/core";

const routes: Routes = [
  {
    path: 'register',
    component: RegisterComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'resetPassword',
    component: ResetPasswordComponent
  },
  {
    path: 'users',
    component: OverviewUsers,
    canActivate: [AuthGuard, GuardADMIN_P],
    // resolve: { users: UsersResolver }
  },
  {
    path: 'client-users/:clientId/:clientName',
    component: OverviewUsers,
    canActivate: [AuthGuard, GuardSUPER_ADMIN_P, Guard_ALL_ACCESS_P],
    resolve: {
      users: ClientUsersResolver
    }
  },
  {
    path: 'users/:userId',
    component: EditUser,
    canActivate: [AuthGuard, GuardADMIN_P],
    resolve: {
      user: UserResolver,
      // projects: ProjectsResolver
    }
  },
  {
    path: 'my-profile',
    component: EditProfile,
    canActivate: [AuthGuard],
    resolve: {
      myProfile: ProfileResolver
    }
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'login'
  },
  {
    path: 'association-tool',
    component: AssociationToolComponent,
    canActivate: [AuthGuard, GuardADMIN_P, Guard_SHIPMENT_P]
  }
];

@NgModule({
  declarations: [
    RegisterComponent,
    LoginComponent,
    ResetPasswordComponent,
    OverviewUsers,
    EditUser,
    EditProfile,
    AssociationToolComponent
  ],
  imports: [
    ReactiveFormsModule,
    CommonModule,
    InputTextModule,
    CheckboxModule,
    ButtonModule,
    DialogModule,
    MessagesModule,
    MessageModule,
    DropdownModule,
    MultiSelectModule,
    InputSwitchModule,
    TabViewModule,
    SharedModule,
    RouterModule.forChild(routes),
    TranslateModule
  ],
  providers: [
    MessageService,
    RegistrationService
  ]
})
export class UserModule { }
