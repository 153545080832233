import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { Geofence } from '../models/geofence';
import { GeofenceService } from './geofence.service';

@Injectable({
  providedIn: 'root'
})
export class GeofencesResolver implements Resolve<Geofence[]> {

  constructor(private geofenceService: GeofenceService) { }

  resolve(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<Geofence[]> {

    return this.geofenceService.getGeofences()
      .pipe(
        map(geofences => {
          if (geofences)
            return geofences;
        }),
        catchError(error => {
          const message = `Retrieval error: ${error}`;
          console.error(message);
          return Observable.throw(error);
        })
      );
  }
}

@Injectable({
  providedIn: 'root'
})
export class GeofenceResolver implements Resolve<Geofence> {

  constructor(private geofenceService: GeofenceService) { }

  resolve(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<Geofence> {

    const id = route.paramMap.get('geofenceId');
    if (isNaN(+id)) {
      const message = `Geofence id was not a number: ${id}`;
      console.error(message);
      return Observable.throw(message);
    }

    return this.geofenceService.getGeofence(+id)
      .pipe(
        map(geofence => {
          if (geofence)
            return geofence;
        }),
        catchError(error => {
          const message = `Retrieval error: ${error}`;
          console.error(message);
          return Observable.throw(error);
        })
      );
  }

}