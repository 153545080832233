import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { Project } from '../models/project';
import { ProjectService } from './project.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectsResolver implements Resolve<Project[]> {

  constructor(private projectService: ProjectService) { }

  resolve(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<Project[]> {

    return this.projectService.getProjects()
      .pipe(
        map(projects => {
          if (projects)
            return projects;
        }),
        catchError(error => {
          const message = `Retrieval error: ${error}`;
          console.error(message);
          return Observable.throw(error);
        })
      );
  }
}

@Injectable({
  providedIn: 'root'
})
export class ProjectResolver implements Resolve<Project> {

  constructor(private projectService: ProjectService) { }

  resolve(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<Project> {

    const id = route.paramMap.get('projectId');
    if (isNaN(+id)) {
      const message = `Project id was not a number: ${id}`;
      console.error(message);
      return Observable.throw(message);
    }

    return this.projectService.getProject(+id)
      .pipe(
        map(project => {
          if (project)
            return project;
        }),
        catchError(error => {
          const message = `Retrieval error: ${error}`;
          console.error(message);
          return Observable.throw(error);
        })
      );
  }

}


@Injectable({
  providedIn: 'root'
})
export class RSSIFilterConfigResolver implements Resolve<Project> {

  constructor(private projectService: ProjectService) { }

  resolve(route: ActivatedRouteSnapshot,
          state: RouterStateSnapshot): Observable<Project> {

    const id = route.paramMap.get('projectId');
    if (isNaN(+id)) {
      const message = `Project id was not a number: ${id}`;
      console.error(message);
      return Observable.throw(message);
    }

    return this.projectService.getRSSIFilterconfig(+id)
      .pipe(
        map(firmwares => {
          if (firmwares)
            return firmwares;
        }),
        catchError(error => {
          const message = `Retrieval error: ${error}`;
          console.error(message);
          return Observable.throw(error);
        })
      );
  }

}
