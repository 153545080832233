import { Injectable } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { tap, map } from 'rxjs/operators';
import { from, Observable, of } from 'rxjs';

declare var google: any;

@Injectable()
export class GeocodeService {
    private geocoder: any;

    constructor(private mapLoader: MapsAPILoader) { }

    private initGeocoder() {
        //console.log('Init geocoder!');
        this.geocoder = new google.maps.Geocoder();
    }

    private waitForMapsToLoad(): Observable<boolean> {
        if (!this.geocoder) {
            return from(this.mapLoader.load())
                .pipe(
                    tap(() => this.initGeocoder()),
                    map(() => true)
                );
        }
        return of(true);
    }

    geocodeAddress(address: string): Observable<string> {
        return new Observable(observer => {
            if(!address){
                observer.next('');
                observer.complete();
                return;
            }

            const request = { address: address };
            return this.waitForMapsToLoad().subscribe(() => {
                this.geocoder.geocode(request, (results, status) => {
                    if (status === "OK") {
                        observer.next(JSON.stringify(results[0].geometry.location));
                    } else {
                        console.log('Error - ', results, ' & Status - ', status);
                        observer.next("");
                    }
                    observer.complete();
                });
            },err=>observer.error(err));
        });
    }

    reverseGeocodeAddress(lat: number, lng: number): Observable<string> {
        return new Observable(observer => {
            if(!lat || !lng){
                observer.next('');
                observer.complete();
                return;
            }

            const request = { lat: lat, lng: lng };
            return this.waitForMapsToLoad().subscribe(() => {
                this.geocoder.geocode({ 'location': request }, (results, status) => {
                    if (status === "OK") {
                        observer.next(results[0].formatted_address);
                    } else {
                        console.log('Error - ', results, ' & Status - ', status);
                        observer.next("");
                    }
                    observer.complete();
                });
            },err=>observer.error(err));
        });
    }
}