import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthenticationService, CLIENT_TYPES } from '../../../authorization/authentication.service';
import { checkClientTypePermission } from '../../base-functions/permission.base-functions';
import { ACToasterService } from '../../services/ac-toaster.service';

@Injectable()
export class Guard_ALL_ACCESS_P implements CanActivate {

    constructor(
        private serviceAuthentication: AuthenticationService,
        private serviceACToaster: ACToasterService
    ) { }

	/**
	 * Validate if user is in a client with ALL_ACCESS type
	 * @param route
	 * @param state
	 * @returns {boolean}
	 */
    public canActivate(): Observable<boolean> | Promise<boolean> | boolean {
        try {
            const currentUser = this.serviceAuthentication.currentUserValue;
            const result = checkClientTypePermission(currentUser, CLIENT_TYPES.ALL_ACCESS);
            if(!result) {
                this.serviceACToaster.showPermissionWarning();
            }

            return result;
        } catch (error) {
            return false;
        }
    }
}
