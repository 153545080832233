import { Pipe, PipeTransform } from '@angular/core';


@Pipe({ name: 'searchPIPE', pure: false })
export class SearchPipe implements PipeTransform {
    transform(list: any[], searchText: string = ''): any[] {
        if (!searchText || searchText == '')
            return list;

        return (list || []).filter(item => {
            const res_one = this.compare(item, searchText);
            if (res_one != null)
                return res_one;


            const res_two = Object.values(item).filter(k => {
                const result_for_each_field = this.compare(k, searchText);

                return result_for_each_field == null || !result_for_each_field ? false : true;
            });

            return res_two.length > 0 ? true : false;
        })
    }

    private compare(item, searchText: string): boolean | null {
        if (!item)
            return false;

        if (typeof item != 'object' && `${item}`.toLowerCase().indexOf(searchText.toLowerCase()) >= 0)
            return true;

        return null;
    }
}