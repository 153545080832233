import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from './api.base.service';

@Injectable({ providedIn: 'root' })
export class AutotestService {
    private autotestsURL = 'autoTests';
    private autotestURL = 'autoTests';

    constructor(private api: ApiService) {
    }
   listAutotests(): Observable<any[]> {
        return this.api.httpGET<any[]>(this.autotestsURL, 'autoTests');
    }

    showAutotest(autotestId): Observable<any> {
        return this.api.httpGET<any>(`${this.autotestURL}/${autotestId}`, 'autoTest');
    }
}
