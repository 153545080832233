import { KEY_LABEL_PAIR } from './KEY_VALUE_PAIR.interface';

export const getACSupportedLanguagesFNC = (): KEY_LABEL_PAIR[] => {
    return [
        { key: 'en', label: 'English' },
        { key: 'es', label: 'Spanish' }
    ];
}
export const findACLanguageByKeyOrNullFNC = (key?: string): KEY_LABEL_PAIR | null => {
    return !key ? null : getACSupportedLanguagesFNC().find(k => k.key == key);
}
