import { Pipe, PipeTransform } from '@angular/core';

import { AuthenticationService } from '../../authorization/authentication.service';

import { convertTemperatureWithUnitFNC } from '../base-functions';

@Pipe({ name: 'tempPIPE' })
export class TemperaturePipe implements PipeTransform {


    constructor(private serviceAuth: AuthenticationService) { }

    transform(value?: any): any {
        return convertTemperatureWithUnitFNC(value);
    }
}