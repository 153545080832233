import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { CommonModule } from '@angular/common';

import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ToastModule } from 'primeng/toast';

import { NgxUiLoaderModule, NgxUiLoaderRouterModule, NgxUiLoaderHttpModule, NgxUiLoaderConfig } from 'ngx-ui-loader';

import { ConfirmToastAC } from './shared/components/ac-confirm-toast/ac-confirm-toast.component';
import { ACToasterService } from './shared/services/ac-toaster.service';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import {
  GuardSUPER_ADMIN_P,
  GuardADMIN_P,
  GuardVIEWER_P,
  Guard_ALL_ACCESS_P,
  Guard_ASSET_TRACKING_P,
  Guard_SHIPMENT_P
} from './shared/guards/permissions/index';

import { ApiService } from './shared/services/api.base.service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';

import { JwtInterceptor } from './authorization/jwt.interceptor';
import { ErrorInterceptor } from './authorization/error.Inspector';
import { AuthenticationService } from './authorization/authentication.service';
import { environment } from '../environments/environment';
import { PreviousRouteService } from './shared/services/previous-route.service';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  pbColor: '#d35a2a'
};

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    ConfirmToastAC,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CommonModule,
    SharedModule,
    MessagesModule,
    MessageModule,
    ToastModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderRouterModule,
    NgxUiLoaderHttpModule.forRoot({ exclude: [environment.baseUrl + 'alarms'], showForeground: true }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

    ACToasterService,
    AuthenticationService,
    ApiService,

    GuardSUPER_ADMIN_P,
    GuardADMIN_P,
    GuardVIEWER_P,

    Guard_ALL_ACCESS_P,
    Guard_ASSET_TRACKING_P,
    Guard_SHIPMENT_P,
    PreviousRouteService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
