import { UUID } from 'angular2-uuid';
import { map, tap, catchError, filter } from 'rxjs/operators';

import { listener_SEARCHBOX } from '../components/search-box/searchbox.listener';

export class SubscribeBase {
    private listSubscribes: { ID: UUID, key: string, refSubscribeObject: any, nextFunc: Function, errFunc: Function, subscribeRef: any }[] = [];

    beforeDestroyFunc: Function;
    afterDestroyFunc: Function;

    public searchTextFOROneItem: String = '';
    fncSearchFOROneItem = (text) => { this.searchTextFOROneItem = text };

    addSubscribe(key: string = '', refSubscribeObject: any, nextFunc: Function, errFunc: Function = null): UUID {
        this.oneitemUnsubscribeAndRemove(key);

        let ID = UUID.UUID();
        let subscribeRef = refSubscribeObject.subscribe(nextFunc);

        this.listSubscribes.push({
            ID,
            key,
            refSubscribeObject,
            nextFunc,
            errFunc,
            subscribeRef
        });

        return ID;
    }

    oneitemUnsubscribeAndRemove(key: string) {
        let foundItemIndex = this.listSubscribes.findIndex(item => item.key == key);
        if (foundItemIndex < 0)
            return;

        this.listSubscribes[foundItemIndex].subscribeRef.unsubscribe();
        this.listSubscribes.splice(foundItemIndex, 1);
    }

    oneitemUnsubscribeAndRemoveByID(ID: UUID) {
        let foundItemIndex = this.listSubscribes.findIndex(item => item.ID == ID);
        if (foundItemIndex < 0)
            return;

        this.listSubscribes[foundItemIndex].subscribeRef.unsubscribe();
        this.listSubscribes.splice(foundItemIndex, 1);
    }

    allitemsUnsubscribeAndRemove() {
        this.listSubscribes.map(item => { return item.ID }).forEach(ID => {
            this.oneitemUnsubscribeAndRemoveByID(ID);
        })
    }

    subscribeSEARCHBOX(key: string, callback: Function): UUID {
        const item = listener_SEARCHBOX.pipe(filter(k => k.key == key), map(k => k.text));
        return this.addSubscribe(key, item, callback);
    }
}
