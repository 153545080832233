import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserModule } from './user/user.module';
import { SharedModule } from './shared/shared.module';

const routes: Routes = [
  { path: 'assets', loadChildren: './assets/assets.module#AssetsModule' },
  { path: 'beacons', loadChildren: './beacons/beacons.module#BeaconsModule' },
  { path: 'projects', loadChildren: './projects/projects.module#ProjectsModule' },
  { path: 'trackers', loadChildren: './trackers/trackers.module#TrackersModule' },
  { path: 'notifications', loadChildren: './notifications/notifications.module#NotificationsModule' },
  { path: 'geofence', loadChildren: './geofence/geofence.module#GeofenceModule' },
  { path: 'receiveddatas', loadChildren: './received-data/ReceivedData.module#ReceivedDataModule' },
  { path: 'dashboard', loadChildren: './dashboard/Dashboard.module#DashboardModule' },
  { path: 'braceletDashboard', loadChildren: './dashboard/overview-bracelet-dashboard/overview-bracelet-dashboard.module#OverviewBraceletDashboardModule' },
  { path: 'clients', loadChildren: './clients/Clients.module#ClientsModule' },
  { path: 'orders', loadChildren: './orders/Orders.module#OrdersModule' },
  { path: 'shipments', loadChildren: './shipments/Shipments.module#ShipmentsModule' },
  { path: 'operations', loadChildren: './operations/Operations.module#OperationsModule' },
  { path: 'cycle-times', loadChildren: './cycle-times/CycleTimes.module#CycleTimesModule' },
  { path: 'delays', loadChildren: './delays/Delays.module#DelaysModule' },
  { path: 'incidents', loadChildren: './incidents/Incidents.module#IncidentsModule' },
  { path: 'subjects', loadChildren: './peoples/peoples.module#PeoplesModule' },
  { path: 'contacts', loadChildren: './contacts/contacts.module#ContactsModule' },
  { path: 'autoTests', loadChildren: './autotest/autotest.module#AutotestModule' },
  { path: 'searchtrackers', loadChildren: './search-trackers/search-trackers.module#SearchTrackersModule' }
];

@NgModule({
  imports: [
    SharedModule,
    UserModule,
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
