import { Component, Input } from '@angular/core';
import { Location } from '@angular/common';

@Component({
    selector: 'card-label',
    templateUrl: './card-label.component.html',
    styleUrls: ['./card-label.component.css']
})
export class CardLabel {
    @Input() label: String = '';
    @Input() subtitle: String = '';
    @Input() isBackArrow: boolean = false;

    constructor(private _Location:Location){

    }
}