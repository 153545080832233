import { Component } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import md5 from 'md5';

import { AuthenticationService, USER_TYPES } from '../../authorization/authentication.service';
import { UserService } from '../../shared/services/user.service';
import { ACToasterService } from '../../shared/services/ac-toaster.service';
import { ClientService } from 'src/app/shared/services/client.service';

import { KEY_LABEL_PAIR, getBrowserTimezoneFNC } from '../../shared/base-functions';
import { getTimezonesFNC, findTimezoneByTimezoneKeyFNC } from '../../shared/base-functions/as-timezones.base-function';
import { validatorEmailFNC, validatorPhoneFNC, validatorPasswordFNC } from '../../shared/custom-form-validators';
import { TranslateService } from "@ngx-translate/core";
import { ProjectService } from 'src/app/shared/services/project.service';

@Component({
  selector: 'edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css']
})
export class EditUser {
  currentUser: any = {};
  editUserForm: FormGroup;
  editUserPasswordForm: FormGroup;
  submitted = false;
  submittedCheckPassword = false;
  passFormSubmitted = false;
  private adminClientId: number;

  get f() {
    return this.editUserForm.controls;
  }

  get fPass() {
    return this.editUserPasswordForm.controls;
  }

  currentAuthUserTypes;
  isSuperAdmin: boolean = false;
  tabIndex: number = 0;
  isCreate: boolean;

  timezones: KEY_LABEL_PAIR[] = getTimezonesFNC();
  roles: { value: number, name: string }[] = [];
  clients: any[] = [];
  allProjects: any[] = [];
  projectOptions: any[] = [];

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private serviceAuthentication: AuthenticationService,
    private serviceUser: UserService,
    private serviceACToaster: ACToasterService,
    private serviceClient: ClientService,
    private translateService: TranslateService,
    private projectService: ProjectService

  ) {
  }

  ngOnInit() {
    this.isCreate = this.route.snapshot.url[1].path === '0';
    this.currentAuthUserTypes = this.serviceAuthentication.currentUserValue.userTypes;
    this.isSuperAdmin = this.currentAuthUserTypes.includes(USER_TYPES.SUPER_ADMIN);
    this.adminClientId = this.serviceAuthentication.currentUserValue.clientId;
    this.onLoadForm();
    this.init();
  }


  async init() {
    const data = JSON.parse(JSON.stringify(this.route.snapshot.data));

    this.currentUser = data['user'];
    this.allProjects = data['projects'];

    // this.projectOptions = this.allProjects.map(project => {
    //   return {
    //     label: project.name,
    //     value: project.id
    //   }
    // });

    if (this.isSuperAdmin) {
      try {
        this.clients = await this.serviceClient.getClients().toPromise();
      } catch (error) {
      }
    } else {
      this.projectService.getProjects().subscribe(
        res => {
          this.projectOptions = res.map(project => {
            return {
              label: project.name,
              value: project.id
            };
          });
          if (this.projectOptions.length <= 0) {
            this.editUserForm.controls['project_ids'].setValue(null);
          }
        });
    }

    this.fillRoles();

    if (this.currentUser && this.currentUser.id && this.currentUser.id > 0) {
      this.prepareForUserEdit();
    }

    this.onLoadForm();
  }

  prepareForUserEdit() {

    this.currentUser.activated = this.currentUser.activated == 1 ? true : false;

    this.currentUser.timezone = findTimezoneByTimezoneKeyFNC(this.currentUser.timezone);

    this.currentUser.client = this.isSuperAdmin ? this.clients.find(k => k.id == this.currentUser.clientId) : { id: this.currentUser.clientId };
  }

  fillRoles() {
    this.roles = this.getRoles();
    if (!this.isSuperAdmin) {
      this.roles.splice(this.roles.findIndex(r => r.value === 1), 1);
    }
  }

  getRoles(): any[] {
    return Object.keys(USER_TYPES).map((type, i) => {
      return {
        value: i + 1,
        label: type.replace('_', ' ')
      }
    });
  }

  onLoadForm() {

    let obj = {
      name: [this.currentUser.name, Validators.required],
      last_name: [this.currentUser.last_name, Validators.required],
      email: [this.currentUser.email, [Validators.required, validatorEmailFNC()]],
      phone: [this.currentUser.phone, [validatorPhoneFNC(false)]],
      activated: [this.currentUser.activated == null || this.currentUser.activated == undefined ? true : this.currentUser.activated],
      timezone: [this.currentUser && this.currentUser.timezone ? this.currentUser.timezone : findTimezoneByTimezoneKeyFNC(getBrowserTimezoneFNC()), Validators.required],
      role_ids: [this.currentUser.role_ids, Validators.required],
      client: [this.isSuperAdmin ? this.currentUser.client : { id: this.serviceAuthentication.currentUserValue.clientId }, this.isSuperAdmin ? Validators.required : null],
      project_ids: [this.currentUser.project_ids]
    };

    if (this.isCreate) {
      this.editUserForm = this.formBuilder.group({
        ...obj,
        createPassword: ['', [Validators.required, validatorPasswordFNC()]],
        createConfirmPassword: ['', Validators.required]
      });
    } else {
      this.editUserForm = this.formBuilder.group(
        obj
      );

      if (this.isSuperAdmin) {
        this.onSelectedClient({ value: this.editUserForm.controls['client'].value });
      } else {
        this.editUserForm.controls['client'].disable();
      }

      this.editUserForm.controls['email'].disable();

      this.editUserPasswordForm = this.formBuilder.group({
        password: ['', [Validators.required, validatorPasswordFNC()]],
        confirmPassword: ['', Validators.required]
      });
    }
  }


  onSelectedClient(event) {
    const currentClient = event.value;
    this.projectOptions = [];
    if (currentClient) {
      this.projectService.getProjectClient(currentClient.id).subscribe(
        res => {
          this.projectOptions = res.map(project => {
            return {
              label: project.name,
              value: project.id
            };
          });
          if (this.projectOptions.length <= 0) {
            this.editUserForm.controls['project_ids'].setValue(null);
          }
        });
    } else {
      this.editUserForm.controls['project_ids'].setValue(null);
    }
  }

  onSubmit() {

    if (this.isCreate) {

      this.submitted = true;
      const data = this.editUserForm.value;

      if (this.editUserForm.invalid || data.createPassword !== data.createConfirmPassword)
        return;

      const requestData = {
        ...data,
        id: 0,
        clientId: data.client ? data.client.id : null,
        activated: data.activated ? 1 : 0,
        timezone: data.timezone.key,
        password: md5(this.editUserForm.controls['createPassword'].value),
      };

      let createUser = this.translateService.instant('Create User');
      let userCreated = this.translateService.instant('User has been created');
      let userNotCreated = this.translateService.instant('User could not be created');
      this.serviceUser.saveUser(requestData)
        .subscribe(
          () => {
            this.serviceACToaster.showSuccessMessage(createUser, userCreated);

            this.router.navigate(['users']);
          },
          () => {
            this.serviceACToaster.showErrorMessage(createUser, userNotCreated);
          }
        );
    } else {

      if (this.tabIndex === 0) {

        this.submitted = true;
        const data = this.editUserForm.value;

        if (this.editUserForm.invalid)
          return;

        const requestData = {
          ...data,
          id: this.currentUser && this.currentUser.id ? this.currentUser.id : 0,
          clientId: data.client ? data.client.id : this.adminClientId,
          activated: data.activated ? 1 : 0,
          timezone: data.timezone.key
        };

        let editUser = this.translateService.instant('Edit User');
        let userUpdate = this.translateService.instant('User has been updated');
        let userNotUpdate = this.translateService.instant('User could not be updated');

        this.serviceUser.saveUser(requestData)
          .subscribe(
            () => {
              this.serviceACToaster.showSuccessMessage(editUser, userUpdate);

              this.router.navigate(['users']);
            },
            () => {
              this.serviceACToaster.showErrorMessage(editUser, userNotUpdate);
            }
          );
      } else if (this.tabIndex === 1) {

        this.passFormSubmitted = true;
        const data = this.editUserPasswordForm.value;
        const userFormData = this.editUserForm.value;

        if (this.editUserPasswordForm.invalid || data.password !== data.confirmPassword) {
          return;
        }

        const requestData = {
          id: this.currentUser && this.currentUser.id ? this.currentUser.id : 0,
          password: md5(this.editUserPasswordForm.controls['password'].value),
          ...userFormData,
          clientId: userFormData.client ? userFormData.client.id : this.adminClientId,
          activated: userFormData.activated ? 1 : 0,
          timezone: userFormData.timezone.key
        };

        let editUser = this.translateService.instant('Edit User');
        let userPassUpdate = this.translateService.instant('User password has been updated');
        let userPassNotUpdate = this.translateService.instant('User password could not be updated');

        this.serviceUser.saveUser(requestData)
          .subscribe(
            () => {
              this.serviceACToaster.showSuccessMessage(editUser, userPassUpdate);

              this.router.navigate(['users']);
            }
            ,
            () => this.serviceACToaster.showErrorMessage(editUser, userPassNotUpdate)
          );
      }
    }
  }

  handleTabChange(e) {
    this.tabIndex = e.index;
  }

  /*Used for check password and confirm password is same or not if not same then generate error message.*/
  focusPasswordFunction() {
    this.submittedCheckPassword = true;
  }
}
