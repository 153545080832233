import {Component, Input} from '@angular/core';

@Component({
    selector: 'as-user-avatar',
    templateUrl: './avatar-image.component.html',
    styleUrls: ['./avatar-image.component.css']
})
export class UserAvatar{
    @Input() diameter: number = 48;
    @Input() imageURL: string = '';
    
}