import { KEY_LABEL_PAIR } from './KEY_VALUE_PAIR.interface';

const getInfo = () => Intl.DateTimeFormat().resolvedOptions();

export const getBrowserTimezoneFNC = () => { return getInfo().timeZone };
export const getBrowserLanguageFNC = () => { return getInfo().locale };


export const getBrowserDateFormatFNC = (): string => {
    return Intl.DateTimeFormat().format();
}