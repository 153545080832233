import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';

import { ClientService } from './client.service';

@Injectable({
    providedIn: 'root'
})
export class ClientsResolver implements Resolve<any[]> {

    constructor(private serviceClient: ClientService) { }

    resolve(): Observable<any[]> {
        return this.serviceClient.getClients();
    }
}