import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from './api.base.service';

@Injectable({ providedIn: 'root' })
export class ProfileService {
    private getMyProfileURL = "profile";
    private saveMyProfileURL = "profile";

    constructor(private api: ApiService) { }

    getMyProfile(): Observable<any> {
        return this.api.httpGET<any>(`${this.getMyProfileURL}`, 'profile');
    }

    saveProfile(reqData): Observable<any> {
        return this.api.httpPOST<any>(this.saveMyProfileURL, reqData, 'profile');
    }
}