import {Component, NgZone, Input, Output, ViewChild, ElementRef, EventEmitter} from '@angular/core';

import {MapsAPILoader, MouseEvent} from '@agm/core';

import {MARKER_LIST_MODEL} from './MARKER_LIST_MODEL.model';
import {convertFullDateFromUTCToUserTimezoneTEXT} from "../../base-functions/as-timezones.base-function";

declare var window: any;

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'ac-custom-maps-shipment-order',
  templateUrl: './ac-custom-maps-shipment-order.component.html',
  styleUrls: ['./ac-custom-maps-shipment-order.component.css']
})
// tslint:disable-next-line:component-class-suffix
export class CustomMapsAC1 {
  @Input() isAdressSearchBox = false;
  @Input() latitude = 0.0;
  @Input() longitude = 0.0;
  @Input() isLocationVisible = false;
  @Input() isMarker = false;
  @Input() isMarkerCircle = false;
  @Input() isHeatmap = false;
  @Input() isInfoWindow = false;
  @Input() circleRadius = 100;
  @Input() isMarkerPositionChange = false;
  @Input() markerIconURL = '';
  @Input() gestureHandling: 'cooperative' | 'greedy' | 'none' | 'auto' = 'auto';
  @Input() scrollwheel = true;
  @Input() zoom: number;
  @Input() maxZoom: number;
  @Input() isAutoFitBound = false;
  @Input() listPolyline: Array<{ latitude: number, longitude: number, label?: string }> = new Array();
  @Input() desPolyline: Array<{ latitude: number, longitude: number, label?: string }> = new Array();
  @Input() oriPolyline: Array<{ latitude: number, longitude: number, label?: string }> = new Array();
  @Input() isOnTransit: number;
  @Input() listMarkers: Array<MARKER_LIST_MODEL> = new Array();
  @Input() listHeatMapData: Array<{ latitude: number, longitude: number }> = new Array();

  @Output() onChangePosition: EventEmitter<any> = new EventEmitter<any>();
  @Output() onChangeCircleRadius: EventEmitter<number> = new EventEmitter<number>();
  @Output() onMapReady: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('search') searchElementRef: ElementRef;
  @ViewChild('AC_CUSTOM_MAP') AC_CUSTOM_MAP: any;
  percentageValue = 0;
  isMapReady = false;
  oriShipmentPolyline: any = [];
  desShipmentPolyline: any = [];
  tempDestinationShipmentPolyline: any = [];
  private googleMap: google.maps.Map = null;
  private googleHeatMap: google.maps.visualization.HeatmapLayer = null;
  trackerLatitude;
  trackerLongitude;
  trackerLabel;
  desLatitude;
  desLongitude;
  desLabel;
  desNearLocation;
  originLatitude;
  originLongitude;

  constructor(
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone
  ) {
  }

  ngOnInit() {
  }

  ngOnChanges() {
    this.fitBound();
  }

  ngAfterViewInit() {
    this.confSearchBox();
  }

  private confSearchBox() {
    if (!this.isAdressSearchBox || !this.searchElementRef)
      return;


    this.mapsAPILoader.load().then(() => {
      let autocomplete = new window.google.maps.places.SearchBox(this.searchElementRef.nativeElement, {
        types: ["address"]
      });
      autocomplete.addListener("places_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: any[] = autocomplete.getPlaces();

          //verify result
          if (place.length <= 0) {
            return;
          }

          //set latitude, longitude and zoom
          this.changeLatLong({
            coords: {
              lat: place[0].geometry.location.lat(),
              lng: place[0].geometry.location.lng()
            }
          });
        });
      });
    });
  }


  mapClicked($event: MouseEvent) {
    this.changeLatLong($event);
  }

  markerDragEnd(m: any, $event: MouseEvent) {
    this.changeLatLong($event);
  }

  changeLatLong($event: MouseEvent) {
    if (!this.isMarkerPositionChange)
      return;

    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;

    this.onChangePosition.emit({
      latitude: this.latitude,
      longitude: this.longitude
    })
  }

  onChangeRadius(event) {
    this.onChangeCircleRadius.emit(Number.parseInt(event))
  }

  onChangeCenter(event) {
    this.onChangePosition.emit({
      latitude: event.lat,
      longitude: event.lng
    })
  }

  mapReady(event) {
    //console.log('Map ready');
    this.isMapReady = true;
    this.onMapReady.emit(event);

    setTimeout(() => this.isHeatmap ? this.prepareHeatMap(event) : this.fitBound(), 250);
  }

  prepareHeatMap(mapInstance: google.maps.Map) {

    let bounds: google.maps.LatLngBounds = new window.google.maps.LatLngBounds();

    this.googleMap = mapInstance;

    const coords: google.maps.LatLng[] = this.listHeatMapData.map(heatMapData => {

      const lat = heatMapData.latitude;
      const lng = heatMapData.longitude;

      bounds.extend(new google.maps.LatLng(lat, lng));

      return new google.maps.LatLng(lat, lng);
    });

    this.googleHeatMap = new google.maps.visualization.HeatmapLayer({
      map: this.googleMap,
      data: coords,
      radius: 50
    });

    this.googleMap.fitBounds(bounds);
  }

  fitBound() {
    if (!this.isAutoFitBound || !this.isMapReady)
      return;

    let bounds: google.maps.LatLngBounds = new window.google.maps.LatLngBounds();

    //TRACKER REALTIME
    if (this.isMarker) {

      if (this.latitude && this.longitude && this.latitude !== 0 && this.longitude !== 0) {

        bounds.extend(new google.maps.LatLng(this.latitude, this.longitude));

        this.AC_CUSTOM_MAP._updateBounds(bounds);

        this.zoom = 15;
      } else {
        this.zoom = 1;
      }
    } else {

      if ((this.listPolyline && this.listPolyline.length > 0) || (this.listMarkers && this.listMarkers.length > 0)) {

        if(this.isOnTransit == 1 ){

          /*For origin location.*/
          this.originLatitude = this.listPolyline[0].latitude;
          this.originLongitude = this.listPolyline[0].longitude;


          /*For show tracker Location with icon.*/
          this.trackerLatitude = this.listPolyline[this.listPolyline.length-2].latitude;
          this.trackerLongitude = this.listPolyline[this.listPolyline.length-2].longitude;
          this.trackerLabel = this.listPolyline[this.listPolyline.length-2].label;

          /*For show Destination Location with icon.*/
          this.desLatitude = this.listPolyline[this.listPolyline.length-1].latitude;
          this.desLongitude = this.listPolyline[this.listPolyline.length-1].longitude;
          this.desLabel = this.listPolyline[this.listPolyline.length-1].label;


          this.tempDestinationShipmentPolyline.push(
            {
              latitude: this.listPolyline[this.listPolyline.length-2].latitude,
              longitude: this.listPolyline[this.listPolyline.length-2].longitude,
              label: this.listPolyline[this.listPolyline.length-2].label
            });
          // this.tempDestinationShipmentPolyline = this.listPolyline[this.listPolyline.length-2];
          this.tempDestinationShipmentPolyline.push(
            {
              latitude: this.listPolyline[this.listPolyline.length-1].latitude,
              longitude: this.listPolyline[this.listPolyline.length-1].longitude,
              label: this.listPolyline[this.listPolyline.length-1].label
            });

          // Remove Last Two records from the listPolyline array.
          this.listPolyline.splice(this.listPolyline.length-2, 1);
          this.listPolyline.splice(this.listPolyline.length-1, 1);


          // tslint:disable-next-line:max-line-length
          const trackerToDestination = Math.round(this.getDistanceFromLatLonInKm(this.trackerLatitude, this.trackerLongitude, this.desLatitude, this.desLongitude));

          // tslint:disable-next-line:max-line-length
          const originToDestination = Math.round(this.getDistanceFromLatLonInKm(this.originLatitude, this.originLongitude, this.desLatitude, this.desLongitude));

          if (trackerToDestination == 0 || originToDestination == 0 || trackerToDestination == originToDestination) {
            this.percentageValue = 100;
          } else {
            this.percentageValue = Math.round(trackerToDestination * 100 / originToDestination);
          }

          // Calculation is used for show the thickness of line from tracker Location to destination location.
          if (0 <= this.percentageValue && 20 >= this.percentageValue) {
            this.desNearLocation = 'M 0,4.3 0,1';
          } else if (21 <= this.percentageValue && 40 >= this.percentageValue) {
            this.desNearLocation = 'M 0,4 0,1';
          } else if (41 <= this.percentageValue && 60 >= this.percentageValue) {
            this.desNearLocation = 'M 0,3 0,1';
          } else if (61 <= this.percentageValue && 80 >= this.percentageValue) {
            this.desNearLocation = 'M 0,2 0,1';
          } else if (81 <= this.percentageValue) {
            this.desNearLocation = 'M 0,1 0,1';
          }


          //TRACKER HISTORICAL
          if (this.listPolyline.length > 0) {
            this.listPolyline
              .filter(k => k.latitude && k.longitude)
              .forEach(k => bounds.extend(new google.maps.LatLng(k.latitude, k.longitude)));
          }

          //DASHBOARD
          if (this.listMarkers.length > 0) {
            this.listMarkers
              .filter(k => k.latitude && k.longitude)
              .forEach(k => bounds.extend(new google.maps.LatLng(k.latitude, k.longitude)));
          }

        }

        if(this.isOnTransit == 2){

          /*For show Destination Location with icon.*/
          this.desLatitude = this.listPolyline[this.listPolyline.length-1].latitude;
          this.desLongitude = this.listPolyline[this.listPolyline.length-1].longitude;
          this.desLabel = this.listPolyline[this.listPolyline.length-1].label;

          //TRACKER HISTORICAL
          if (this.listPolyline.length > 0) {
            this.listPolyline
              .filter(k => k.latitude && k.longitude)
              .forEach(k => bounds.extend(new google.maps.LatLng(k.latitude, k.longitude)));
          }

          //DASHBOARD
          if (this.listMarkers.length > 0) {
            this.listMarkers
              .filter(k => k.latitude && k.longitude)
              .forEach(k => bounds.extend(new google.maps.LatLng(k.latitude, k.longitude)));
          }

        }

        if(this.isOnTransit == 0){

          /*For show Destination Location with icon.*/
          this.desLatitude = this.listPolyline[this.listPolyline.length-1].latitude;
          this.desLongitude = this.listPolyline[this.listPolyline.length-1].longitude;
          this.desLabel = this.listPolyline[this.listPolyline.length-1].label;

          //TRACKER HISTORICAL
          if (this.listPolyline.length > 0) {
            this.listPolyline
              .filter(k => k.latitude && k.longitude)
              .forEach(k => bounds.extend(new google.maps.LatLng(k.latitude, k.longitude)));
          }

          //DASHBOARD
          if (this.listMarkers.length > 0) {
            this.listMarkers
              .filter(k => k.latitude && k.longitude)
              .forEach(k => bounds.extend(new google.maps.LatLng(k.latitude, k.longitude)));
          }

        }

        this.AC_CUSTOM_MAP._updateBounds(bounds);
      } else {
        this.zoom = 1;
      }
    }
  }

  clickOnListMarkers(marker: MARKER_LIST_MODEL) {
    if (!marker.callbackMarkerClick)
      return;

    marker.callbackMarkerClick(marker);
  }

  getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    const R = 6371; // Radius of the earth in kilometers
    const dLat = this.deg2rad(lat2 - lat1); // deg2rad below
    const dLon = this.deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in KM
    return d;
  }

  deg2rad(deg) {
    return deg * (Math.PI / 180);
  }

  // setCenter(latitude, longitude){
  //     if(!this.isMapReady)
  //         return;

  //     this.latitude = latitude;
  //     this.longitude = longitude;
  //     this.AC_CUSTOM_MAP['_setCenter']();
  // }
}


