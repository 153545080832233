import { Component, Input } from '@angular/core';

@Component({
    selector: 'ac-map-autofit-toggle-btn',
    templateUrl: './ac-map-autofit-toggle-btn.component.html',
    styleUrls: ['./ac-map-autofit-toggle-btn.component.css']
})
export class MapAutoFitToggleBtnAC {
    @Input() initialAutoFit: boolean = false;

    public value: boolean = false;

    ngOnInit() { this.init(); }
    ngOnChanges() { this.init(); }

    init() {
        this.value = this.initialAutoFit;
    }

}