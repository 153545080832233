import {Injectable} from '@angular/core';
import {Resolve, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';

import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {Contact} from '../models/contact.model';
import {ContactService} from './contact.service';


@Injectable({
  providedIn: 'root'
})

export class ContactsResolver implements Resolve<Contact> {

  constructor(private contactService: ContactService) {
  }

  resolve(route: ActivatedRouteSnapshot,
          state: RouterStateSnapshot): Observable<Contact> {


    const id = route.paramMap.get('personalId');

    return this.contactService.getContact(id)
      .pipe(
        map(people => {
          if (people) {
            return people;
          }
        }),
        catchError(error => {
          const message = `Retrieval error: ${error}`;
          console.error(message);
          return Observable.throw(error);
        })
      );
  }

}
@Injectable({
  providedIn: 'root'
})

export class ContactHistoryResolver implements Resolve<any[]> {

  constructor() { }

  resolve(route: ActivatedRouteSnapshot,
          state: RouterStateSnapshot): Observable<any[]> {
    return;
  }
}

@Injectable({
  providedIn: 'root'
})

export class ContactGraphicResolver implements Resolve<any[]> {

  constructor() { }

  resolve(route: ActivatedRouteSnapshot,
          state: RouterStateSnapshot): Observable<any[]> {
    return;
  }
}
