import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { ApiService } from './api.base.service';

@Injectable({ providedIn: 'root' })
export class ShipmentService {
    private shipmentsURL = 'shipments';
    private saveShipmentURL = 'saveShipment';

    constructor(private api: ApiService) {
    }
 
    listShipments(): Observable<any[]> {
        return this.api.httpGET<any[]>(this.shipmentsURL, 'shipments');
    }

    showShipment(shipmentId): Observable<any> {
        if (shipmentId === 0) {
            return of(this.newShipment());
        }

        return this.api.httpGET<any>(`${this.shipmentsURL}/${shipmentId}`, 'shipment');
    }

    newShipment(): any {
        return {
          id: 0,
          state: 'WAITING'
        };
      }

    saveShipment(shipment: any): Observable<any> {
        return this.api.httpPOST<any>(this.saveShipmentURL, { shipment: shipment });
    }
}