import { Component, Input, ChangeDetectorRef } from '@angular/core';

import { environment } from 'src/environments/environment.prod';

@Component({
    selector: 'asset-image',
    templateUrl: './asset-image.component.html',
    styleUrls: ['./asset-image.component.css']
})
export class AssetImage {

    @Input() diameter: number = 24;
    @Input() imageName: string = '';    
    
    imageURL: string;
    
    constructor(
        private cdr: ChangeDetectorRef
    ){}

    ngAfterViewInit() {
        let imageURL;
        if(this.imageName && this.imageName !== '') {
            imageURL = `${environment.assetImageBaseURL}${this.imageName}.png`;
        }
        else {
            imageURL = '/../../../assets/left-menu-icons/assets.svg';
        }

        this.imageURL = imageURL;

        this.cdr.detectChanges();
    }
}