import { Injector } from '@angular/core'
import { ElementRef, TemplateRef, ViewContainerRef } from '@angular/core';

import { AuthenticationService } from '../../../authorization/authentication.service';

import { SubscribeBase } from '../../bases/subscribe.base';
import { checkClientTypePermission } from '../../base-functions/permission.base-functions';

export class ClientPermissionDirectivesBase extends SubscribeBase {
    private serviceAuthentication: AuthenticationService;

    private minClientType: number;

    constructor(private injector: Injector,
        private element: ElementRef,
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        minClientType: number) {

        super();

        this.serviceAuthentication = this.injector.get(AuthenticationService);

        this.minClientType = minClientType;
        this.addSubscribe('KEY-2', this.serviceAuthentication.currentUser, () => this.check(), () => this.check());
    }

    ngOnInit() { }

    ngAfterViewInit() {
        setTimeout(() => {
            this.check();
        }, 555)
    }

    ngOnDestroy() {
        this.allitemsUnsubscribeAndRemove();
    }

    check() {
        const currentUser = this.serviceAuthentication.currentUserValue;
        const minClientType = this.minClientType;

        if (!this.templateRef || !this.viewContainer)
            return;

        const res = checkClientTypePermission(currentUser, minClientType);
        if (res)
            this.createEmbed();
        else
            this.clearTemp();
    }

    private clearTemp() {
        this.viewContainer.clear();
    }
    private createEmbed() {
        this.clearTemp();
        this.viewContainer.createEmbeddedView(this.templateRef);
    }
}