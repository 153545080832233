import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';

import {ApiService} from './api.base.service';

import {Contact} from '../models/contact.model';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  private baseURL = 'bracelet_contacts';

  constructor(private api: ApiService) {
  }

  getContact(personalId: string): Observable<Contact> {
    return this.api.httpGET(this.baseURL + '?personal_id=' + personalId, 'braceletContacts');
  }
}
