import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from './api.base.service';

@Injectable({ providedIn: 'root' })
export class ClientService {
    private clientsBaseURL = "clients";
    private clientSaveAndUpdateURL = 'client';

    constructor(private api: ApiService) {
    }

    getClients(): Observable<any[]> {
        return this.api.httpGET<any[]>(this.clientsBaseURL, 'clients');
    }

    clientSaveAndUpdateAndDelete(client: any) {
        return this.api.httpPOST<any>(this.clientSaveAndUpdateURL, client, 'client');
    }
}