import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'small-button',
    templateUrl: './small-button.component.html',
    styleUrls: ['./small-button.component.css']
})
export class SmallButton {
    @Input() label: String = '';
    @Input() isDisabled: boolean = false;
    @Input() routerLink: String;
    @Input() btnType: null | 'ORANGE' | 'SUCCESS' | 'DANGER' = null;

    @Output() onClick:EventEmitter<null>=new EventEmitter<null>();

    private activeBtnType:String = 'ORANGE';
    
}