import { Component, OnInit, NgZone } from '@angular/core';

import { AuthenticationService } from './authorization/authentication.service';
import { ACToasterService } from './shared/services/ac-toaster.service';

import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { TranslateService } from '@ngx-translate/core';

am4core.useTheme(am4themes_animated);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  title = 'Accent Systems';

  isLoggedIn: boolean = false;

  private intervalAlarmCall: any = null;

  constructor(
    private authenticationService: AuthenticationService,
    private serviceACToaster: ACToasterService,
    private translateService: TranslateService
  ) {
    translateService.addLangs(['en', 'es']);
  }

  ngOnInit() {
    this.authenticationService.currentUser.subscribe((user) => {
      this.isLoggedIn = user != null;
      this.loadScript('assets/js/limitless.js');

      if (this.isLoggedIn) {

        /*For the Bracelet client Alarm's API is not call.*/
        //@ts-ignore
        if (this.authenticationService.currentUser.source.value.clientType != 4) {
          this.buildIntervalAlarmCall();
        }

        //@ts-ignore
        this.translateService.setDefaultLang(this.authenticationService.currentUser.source.value.language);

        // the lang to use, if the lang isn't available, it will use the current loader to get them
        //@ts-ignore
        this.translateService.use(this.authenticationService.currentUser.source.value.language);
      } else {
        this.serviceACToaster.clearSeesionAlarms();
        this.removeIntervalAlarmCall();
      }
    });
  }

  public loadScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const bodyChildren = body.children as HTMLCollection;
    const bodyChildElementCount = body.childElementCount;

    for (let i = 0; i < bodyChildElementCount; i++) {
      let currentItem = bodyChildren.item(i);
      const currentItemLocalName = currentItem.localName;
      if (!currentItemLocalName || currentItemLocalName !== 'script') {
        continue;
      }
      const currentScriptElement = currentItem as HTMLScriptElement;
      if (currentScriptElement.src && currentScriptElement.src.indexOf('limitless') >= 0) {
        currentItem.remove();
        break;
      }
    }

    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = false;
    script.type = 'text/javascript';
    body.appendChild(script);
  }

  buildIntervalAlarmCall() {
    this.removeIntervalAlarmCall();
    this.intervalAlarmCall = setInterval(() => this.serviceACToaster.getAlarmsFromAPI(), 30000);
  }

  removeIntervalAlarmCall() {
    if (this.intervalAlarmCall) {
      clearInterval(this.intervalAlarmCall);
    }
  }


}
