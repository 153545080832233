import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable} from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { People } from '../models/people';
import { PeoplesService } from './peoples.service';

@Injectable({
  providedIn: 'root'
})
export class PeoplesResolver implements Resolve<People[]> {

  constructor(private peoplesService: PeoplesService) { }

  resolve(route: ActivatedRouteSnapshot,
          state: RouterStateSnapshot): Observable<People[]> {

    return this.peoplesService.getPeoples()
      .pipe(
        map(peoples => {
          if (peoples) {
            return peoples;
          }
        }),
        catchError(error => {
          const message = `Retrieval error: ${error}`;
          console.error(message);
          return Observable.throw(error);
        })
      );
  }
}

@Injectable({
  providedIn: 'root'
})
export class PeopleResolver implements Resolve<People> {

  constructor(private peoplesService: PeoplesService) { }

  resolve(route: ActivatedRouteSnapshot,
          state: RouterStateSnapshot): Observable<People> {

    const id = route.paramMap.get('projectId');

    /*if (isNaN(+id)) {
      const message = `Project id was not a number: ${id}`;
      console.error(message);
      return Observable.throw(message);
    }*/

    return this.peoplesService.getPeople(id)
      .pipe(
        map(people => {
          if (people)
            return people;
        }),
        catchError(error => {
          const message = `Retrieval error: ${error}`;
          console.error(message);
          return Observable.throw(error);
        })
      );
  }

}


/*This Reolver is used for edit and add new People data*/
/*@Injectable({
  providedIn: 'root'
})
export class PeoplesResolver implements Resolve<People> {

  constructor(private peopleService: PeoplesService) { }

  resolve(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<People> {

    const id = route.paramMap.get('projectId');
    if (isNaN(+id)) {
      const message = `Project id was not a number: ${id}`;
      console.error(message);
      return Observable.throw(message);
    }

    // @ts-ignore
    return this.peopleService.getPeoples(+id)
      .pipe(
        map(project => {
          if (project) {
            return project;
          }
        }),
        catchError(error => {
          const message = `Retrieval error: ${error}`;
          console.error(message);
          return Observable.throw(error);
        })
      );
  }

}*/



/*This Reolver is used for Get Peoples data/getSubjects*/
/*@Injectable({
  providedIn: 'root'
})
export class PeoplesResolverService implements Resolve<People[]> {

  constructor(private peopleService: PeoplesService) { }

  resolve(route: ActivatedRouteSnapshot,
          state: RouterStateSnapshot): Observable<People[]> {

    // @ts-ignore
    return this.peopleService.getPeople()
      .pipe(
        map(peoples => {
          if (peoples) {
            return peoples;
          }
        }),
        catchError(error => {
          const message = `Retrieval error: ${error}`;
          console.error(message);
          return Observable.throw(error);
        })
      );
  }
}*/
