import { Component, Input } from '@angular/core';

import { AuthenticationService } from '../../../authorization/authentication.service';

import { SubscribeBase } from '../../bases/subscribe.base';
import { environment } from 'src/environments/environment.prod';

@Component({
    selector: 'ac-auth-user-avatar',
    templateUrl: './ac-auth-user-avatar.component.html'
})
export class AuthUserAvatarAC extends SubscribeBase {
    @Input() diameter: number = 38;

    imageURL: string = '';

    constructor(private serviceAuthentication: AuthenticationService) {
        super();

        this.addSubscribe(
            'REFRESH_AUTH_USER_SESSION_VERSION', 
            this.serviceAuthentication.listenerSessionVersion, 
            () => this.prepareImageURL());
    }

    ngOnInit() {
        
    }

    ngAfterViewInit(){
        this.prepareImageURL();
    }

    ngOnDestroy() {
        this.allitemsUnsubscribeAndRemove();
    }

    prepareImageURL() {
        this.imageURL = `${environment.profileImageBaseURL}${this.serviceAuthentication.currentUserValue.username}.png?v=${this.serviceAuthentication.session_version}`;
    }
}