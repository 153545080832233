import { Pipe, PipeTransform } from '@angular/core';

import {convertFullDateFromUTCToUserTimezoneTEXT} from '../../base-functions/as-timezones.base-function';

@Pipe({ name: 'acFullDatePIPE' })
export class FullDatePipeAC implements PipeTransform {
    transform(value: any): String {
        return convertFullDateFromUTCToUserTimezoneTEXT(value);
    }
}
