import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthenticationService, USER_TYPES } from '../../../authorization/authentication.service';
import { ACToasterService } from '../../services/ac-toaster.service';
import { chekcPermissionFunc } from '../../base-functions/permission.base-functions';

@Injectable()
export class GuardADMIN_P implements CanActivate {

    constructor(
        private route: Router,
        private serviceAuthentication: AuthenticationService,
        private serviceACToaster: ACToasterService
    ) { }

	/**
	 * Validate if user is logged in. If not, redirect to Login page
	 * @param route
	 * @param state
	 * @returns {boolean}
	 */
    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        try {
            const currentUser = this.serviceAuthentication.currentUserValue;
            const result =  chekcPermissionFunc(currentUser, USER_TYPES.ADMIN);
            if(!result)
                this.serviceACToaster.showPermissionWarning();

            return result;
        } catch (error) {
            return false;
        }
    }
}
