import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { AlarmService } from './alarm.service';
import {TranslateService} from '@ngx-translate/core';
import {AuthenticationService} from "../../authorization/authentication.service";

const TOAST_TYPES = {
    MAIN_TOAST: 'MAIN_TOAST',
    ALARM_TOAST: 'ALARM_TOAST',
    CONFIRM_TOAST: 'CONFIRM_TOAST',
    PERMISSIONS_TOAST: 'PERMISSIONS_TOAST'
};

@Injectable({ providedIn: 'root' })
export class ACToasterService {
    public sessionAlarms: any[] = [];

    constructor(
        private servicePNGMessage: MessageService,
        private serviceAlarm: AlarmService,
        private translateService: TranslateService,
        private authenticationService: AuthenticationService,
    ) { }

    clearMainToast() {
        this.servicePNGMessage.clear(TOAST_TYPES.MAIN_TOAST);
    }
    showSuccessMessage(summary: string, detail: string) {
        this.clearMainToast();
        this.servicePNGMessage.add({
            key: TOAST_TYPES.MAIN_TOAST,
            severity: 'success',
            summary: summary,
            detail: detail
        });
    }
    showErrorMessage(summary: string, detail: string) {
        this.clearMainToast();
        this.servicePNGMessage.add({
            key: TOAST_TYPES.MAIN_TOAST,
            severity: 'error',
            summary: summary,
            detail: detail
        });
    }


    clearSessionAlarmsToasters() {
        this.servicePNGMessage.clear(TOAST_TYPES.ALARM_TOAST);
    }
    clearSeesionAlarms() {
        this.sessionAlarms = [];
        this.clearSessionAlarmsToasters();
    }
    getAlarmsFromAPI = async () => {
        try {
            const newAlarms = await this.serviceAlarm.getAlarms().toPromise();
            if (!newAlarms) {
                return;
            }

            this.prepareAlarms(newAlarms);
        } catch (error) { }
    }
    private prepareAlarms(newAlarms: any[]) {
        this.sessionAlarms = newAlarms.concat(this.sessionAlarms);

        this.clearSessionAlarmsToasters();
        const part = newAlarms.slice(0, 10);
        part.filter(k => k.alarm_type == 'SHOCK').forEach(item => this.showAlarmMessageOnToaster(item, 'warn'));
        part.filter(k => k.alarm_type != 'SHOCK').forEach(item => this.showAlarmMessageOnToaster(item, 'error'));
    }
    private showAlarmMessageOnToaster(item, severity) {
        this.servicePNGMessage.add({
            key: 'ALARM_TOAST',
            severity: severity,
            life: 30000,
            data: item
        });
    }



    showPermissionWarning() {
        this.servicePNGMessage.add({
            key: TOAST_TYPES.PERMISSIONS_TOAST,
            severity: 'error',
            life: 15000,
            detail: `You don't have permission for this page.`,
            summary: 'Unauthorized'
        });
    }



    private clearConfirmToast() {
        this.servicePNGMessage.clear(TOAST_TYPES.CONFIRM_TOAST);
    }
    showConfirmMessage(
        type: 'ERROR' | 'WARN' | 'SUCCESS' | 'INFO',
        summary: string,
        detail: string,
        onConfirm: Function,
        onConfirmText: string,
        onReject: Function,
        onRejectText: string,
        life: number = 15000
    ) {
        this.clearConfirmToast();
        this.servicePNGMessage.add({
            key: TOAST_TYPES.CONFIRM_TOAST,
            life: life,
            sticky: false,
            summary: summary,
            detail: detail,
            data: {
                onConfirm: () => { this.clearConfirmToast(); onConfirm(); },
                onConfirmText,
                onReject: () => { this.clearConfirmToast(); onReject(); },
                onRejectText,
                type
            }
        });
    }
    showSuccessConfirmToaster(summary: string, detail: string) {
        this.showConfirmMessage(
            'SUCCESS',
            summary,
            detail,
            () => { },
            'OK',
            () => { },
            ''
        );
    }
    showWarningConfirmToaster(summary: string, detail: string) {
        this.showConfirmMessage(
            'WARN',
            summary,
            detail,
            () => { },
            'OK',
            () => { },
            ''
        );
    }



    showRemoveConfirmToaster(type: string, name: string, onConfirm: Function) {
      const dynamicText = this.translateService.instant(type);
      const message = this.translateService.instant('Are you sure you want to delete this ');
      const yesText = this.translateService.instant('Yes');
      const cancelText = this.translateService.instant('Cancel');
      // @ts-ignore
      const language = this.authenticationService.currentUser.source.value.language;

      // @ts-ignore
      // tslint:disable-next-line:triple-equals
      if (language == 'es') {
        console.log(language);
        this.showConfirmMessage(
          'ERROR',
          ``,
          message + ` ` + dynamicText + ` ` + `${name} ` + `?`,
          () => { onConfirm(); },
          yesText,
          () => { },
          cancelText,
          60000
        );
      } else {
        this.showConfirmMessage(
          'ERROR',
          ``,
          message + `${name} ` + dynamicText + `?`,
          () => { onConfirm(); },
          yesText,
          () => { },
          cancelText,
          60000
        );
      }

    }
    showRemoveSuccesToaster(type: string, listRef: any[], ref: any, accessor: string): any[] {
        const list = JSON.parse(JSON.stringify(listRef));

        const index = list.findIndex(k => k[accessor] == ref[accessor]);
        list.splice(index, 1);
        this.showSuccessConfirmToaster('', `This ${type} was successfully deleted`);

        return JSON.parse(JSON.stringify(list));
    }
    showRemoveWarningToaster(error: string) {
        this.showWarningConfirmToaster('', error);
    }



}
