import { ValidatorFn, AbstractControl } from "@angular/forms";

import * as google from 'google-libphonenumber';

export const validatorPhoneFNC = (isRequired: boolean): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: any } | null => {
        if (!isRequired)
            return null;

        const instance_G = google;
        const instance_PhoneNumberUtil = instance_G.PhoneNumberUtil.getInstance();

        const phoneValue = control.value;

        const countryCodes: string[] = instance_G.shortnumbermetadata.countryCodeToRegionCodeMap[0];
        const isInvalid: boolean = countryCodes
            .map((cR: string) => {
                try {
                    return instance_PhoneNumberUtil.isValidNumber(instance_PhoneNumberUtil.parse(phoneValue, cR))
                } catch (error) {
                    return false;
                }
            })
            .filter(k => k == true)
            .length <= 0;

        return isInvalid ? { 'DOESNOT_MATCHED_ANY_COUNTRY_PHONE_REGEX': phoneValue } : null;
    };
}