import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { Tracker } from '../models/tracker';
import { TrackerService } from './tracker.service';
import { TrackerBriefData } from '../models/trackerBriefData';
import { Utils } from '../utils/Utils';
import { TemperatureResult } from '../models/temperatureResult';
import { TrackerParameters } from '../models/trackerParameters';
import { HumidityResult } from '../models/HumidityResult';

@Injectable({
  providedIn: 'root'
})
export class TrackersResolver implements Resolve<Tracker[]> {

  constructor(private trackerService: TrackerService) { }

  resolve(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<Tracker[]> {

    return this.trackerService.getTrackers()
      .pipe(
        map(trackers => {
          if (trackers)
            return trackers;
        }),
        catchError(error => {
          const message = `Retrieval error: ${error}`;
          console.error(message);
          return Observable.throw(error);
        })
      );
  }
}

@Injectable({
  providedIn: 'root'
})
export class TrackerResolver implements Resolve<Tracker> {

  constructor(private trackerService: TrackerService) { }

  resolve(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<Tracker> {

    const id = route.paramMap.get('trackerId');
    if (isNaN(+id)) {
      const message = `Tracker id was not a number: ${id}`;
      console.error(message);
      return Observable.throw(message);
    }

    if(id=='0')
      return null;

    return this.trackerService.getTracker(+id)
      .pipe(
        map(trackers => {
          if (trackers)
            return trackers;
        }),
        catchError(error => {
          const message = `Retrieval error: ${error}`;
          console.error(message);
          return Observable.throw(error);
        })
      );
  }
}
@Injectable({
  providedIn: 'root'
})
export class TrackerBriefResolver implements Resolve<TrackerBriefData> {

  constructor(private trackerService: TrackerService) { }

  resolve(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<TrackerBriefData> {

    const id = route.paramMap.get('trackerId');
    if (isNaN(+id)) {
      const message = `Tracker id was not a number: ${id}`;
      console.error(message);
      return Observable.throw(message);
    }

    return this.trackerService.getTrackerBriefData(+id)
      .pipe(
        map(trackerData => {
          if (trackerData) {
            trackerData.batteryTimestampDate = Utils.convertStringToDate(trackerData.batteryTimestamp);
            trackerData.temperatureTimestampDate = Utils.convertStringToDate(trackerData.temperatureTimestamp);
            trackerData.locationTimestampDate = Utils.convertStringToDate(trackerData.locationTimestamp);
            return trackerData;
          }
        }),
        catchError(error => {
          const message = `Retrieval error: ${error}`;
          console.error(message);
          return Observable.throw(error);
        })
      );
  }
}

@Injectable({
  providedIn: 'root'
})
export class TrackerTemperatureResolver implements Resolve<TemperatureResult[]> {

  constructor(private trackerService: TrackerService) { }

  resolve(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<TemperatureResult[]> {

    const id = route.paramMap.get('trackerId');
    if (isNaN(+id)) {
      const message = `Tracker id was not a number: ${id}`;
      console.error(message);
      return Observable.throw(message);
    }

    return this.trackerService.getTrackerTemperatureData(+id);
  }
}

@Injectable({
  providedIn: 'root'
})
export class TrackerHumidityResolver implements Resolve<HumidityResult[]> {

  constructor(private trackerService: TrackerService) { }

  resolve(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<HumidityResult[]> {

    const id = route.paramMap.get('trackerId');
    if (isNaN(+id)) {
      const message = `Tracker id was not a number: ${id}`;
      console.error(message);
      return Observable.throw(message);
    }

    return this.trackerService.getTrackerHumidityData(+id);
  }
}

@Injectable({
  providedIn: 'root'
})
export class TrackerParametersResolver implements Resolve<TrackerParameters> {

  constructor(private trackerService: TrackerService) { }

  resolve(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<TrackerParameters> {

    return this.trackerService.getTrackerParameters()
      .pipe(
        map(parameters => {
          if (parameters) {
            return parameters;
          }
        }),
        catchError(error => {
          const message = `Retrieval error: ${error}`;
          console.error(message);
          return Observable.throw(error);
        })
      );
  }
}
