import { Component } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import md5 from 'md5';

import { AuthenticationService } from '../../authorization/authentication.service';
import { ACToasterService } from '../../shared/services/ac-toaster.service';
import { ProfileService } from 'src/app/shared/services/profile.service';

import { KEY_LABEL_PAIR, getBrowserTimezoneFNC, getACSupportedLanguagesFNC, getACSupportedTemperatureFNC, findACLanguageByKeyOrNullFNC, findACTemperatureByKeyOrNullFNC } from '../../shared/base-functions';
import { getTimezonesFNC, findTimezoneByTimezoneKeyFNC } from '../../shared/base-functions/as-timezones.base-function';
import { FileUploadWithSignedURL } from '../../shared/bases/file-upload.base';

import { validatorPhoneFNC, validatorPasswordFNC } from '../../shared/custom-form-validators';
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'edit-profile',
    templateUrl: './edit-profile.component.html',
    styleUrls: ['./edit-profile.component.css']
})
export class EditProfile {
    private myProfile: any = {};
    myProfileForm: FormGroup;
    myProfilePasswordForm: FormGroup;
    submitted = false;
    passFormSubmitted = false;
    get f() { return this.myProfileForm.controls; }
    get fPass() { return this.myProfilePasswordForm.controls; }

    timezones: KEY_LABEL_PAIR[] = getTimezonesFNC();
    supportedLangs: KEY_LABEL_PAIR[] = getACSupportedLanguagesFNC();
    supportedTemps: KEY_LABEL_PAIR[] = getACSupportedTemperatureFNC();

    tabIndex: number = 0;

    constructor(private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private serviceAuthentication: AuthenticationService,
        private serviceProfile: ProfileService,
        private serviceACToaster: ACToasterService,
        private translateService: TranslateService
    ) { }

    ngOnInit() {
        const data = this.route.snapshot.data['myProfile'];
        this.myProfile = data ? data : {};
        this.onLoadForm();
    }

    onLoadForm() {
        this.myProfileForm = this.formBuilder.group({
            name: [this.myProfile.name, Validators.required],
            last_name: [this.myProfile.last_name, Validators.required],
            email: [this.myProfile.email, Validators.required],
            phone: [this.myProfile.phone, [validatorPhoneFNC(false)]],
            timezone: [findTimezoneByTimezoneKeyFNC(this.myProfile && this.myProfile.timezone ? this.myProfile.timezone : getBrowserTimezoneFNC()), Validators.required],
            temperature: [findACTemperatureByKeyOrNullFNC(this.myProfile.temperature), Validators.required],
            language: [findACLanguageByKeyOrNullFNC(this.myProfile.language), Validators.required],
        });

        this.myProfileForm.controls['email'].disable();

        this.myProfilePasswordForm = this.formBuilder.group({
            password: ['', [Validators.required, validatorPasswordFNC()]],
            confirmPassword: ['', Validators.required]
        });
    }

    onSubmit() {

        if(this.tabIndex === 0) {

            this.submitted = true;
            const data = this.myProfileForm.value;

            if (this.myProfileForm.invalid)
                return;

            const reqData = {
                ...data,
                email: this.myProfileForm.controls['email'].value,
                timezone: data.timezone.key,
                language: data.language.key,
                temperature: data.temperature.key
            };

          let profile = this.translateService.instant('Profile');
          let notSaved = this.translateService.instant('Your profile could not be saved');

            this.serviceProfile.saveProfile(reqData)
                .subscribe(
                    () => {

                      let isSaved = this.translateService.instant('Your profile has been saved');
                        this.serviceACToaster.showSuccessMessage(profile, isSaved);
                        this.serviceAuthentication.updatedAuthUserProfile(reqData.name, reqData.last_name, reqData.timezone, reqData.temperature, reqData.language);
                    },
                    () => this.serviceACToaster.showErrorMessage(profile, notSaved)
                );
        }
        else if(this.tabIndex === 1) {

            this.passFormSubmitted = true;
            const data = this.myProfilePasswordForm.value;

            if (this.myProfilePasswordForm.invalid || data.password !== data.confirmPassword){
                return;
            }

            const reqData = {
                password: md5(this.myProfilePasswordForm.controls['password'].value)
            };

          let profile = this.translateService.instant('Profile');
          let passUpdate = this.translateService.instant('Your password has been updated');
          let passNotUpdate = this.translateService.instant('Your password could not be updated');

          this.serviceProfile.saveProfile(reqData)
                .subscribe(
                    () => {
                        this.serviceACToaster.showSuccessMessage(profile, passUpdate);
                    },
                    () => this.serviceACToaster.showErrorMessage(profile, passNotUpdate)
                );
        }
    }


    onChangeAvatar() {
        const el = document.createElement('input');
        el.type = 'file';
        el.accept = 'image/*';
        el.onchange = (event) => this.uploadAvatar(event);
        el.click();
    }
    async uploadAvatar(event) {
        if (!event || !event.currentTarget || !event.currentTarget.files)
            return;

        const file = event.currentTarget.files[0];

        let profileImage = this.translateService.instant('Profile Image');
        let imageUpdate = this.translateService.instant('Profile image has been uploaded.');
        let imageNotUpdate = this.translateService.instant('Profile image has been not uploaded.');
        new FileUploadWithSignedURL(file, this.myProfile.uploadURL).doFileUpload().subscribe(
            (res) => {
                this.serviceAuthentication.changeSessionVersion();
                this.serviceACToaster.showSuccessMessage(profileImage, imageUpdate);
            },
            err => {
                this.serviceACToaster.showSuccessMessage(profileImage, imageNotUpdate);
            });
    }
}
