import { authUserTempKey } from '../../authorization/authentication.service';
import { KEY_LABEL_PAIR } from './KEY_VALUE_PAIR.interface';

export const SELECTOR_TEPERATURE = {
    CELSIUS: 'CELSIUS',
    FAHRENHEIT: 'FAHRENHEIT',
}
export const getACSupportedTemperatureFNC = (): KEY_LABEL_PAIR[] => {
    return [
        { key: SELECTOR_TEPERATURE.CELSIUS, label: 'Celsius (°C)', unit: '°C' },
        { key: SELECTOR_TEPERATURE.FAHRENHEIT, label: 'Fahrenheit (°F)', unit: '°F' },
    ];
}
export const findACTemperatureByKeyOrNullFNC = (key?: string): KEY_LABEL_PAIR | null => {
    return !key ? null : getACSupportedTemperatureFNC().find(k => k.key == key);
}

export const getAuthUserTemperature = (): KEY_LABEL_PAIR => { return findACTemperatureByKeyOrNullFNC(authUserTempKey) }

export const convertTemperatureFNC = (value):Number | '' => {
    let tmpType: KEY_LABEL_PAIR = findACTemperatureByKeyOrNullFNC(authUserTempKey);
    let temp: Number = 0.0;

    if (!value || !isNumber(value))
        return '';
    else
        value = Number.parseFloat(value);

    switch (tmpType ? tmpType.key : null) {
        case SELECTOR_TEPERATURE.CELSIUS:
            temp = value;
            break;
        case SELECTOR_TEPERATURE.FAHRENHEIT:
            temp = (value * 180 / 100) + 32;
            break;
        default:
            temp = value;
    }

    return temp;
}

export const convertTemperatureWithUnitFNC = (value): string => {
    let tmpType: KEY_LABEL_PAIR = findACTemperatureByKeyOrNullFNC(authUserTempKey);
    if(!value)
        return `${tmpType ? tmpType.unit : ''}`;

    let temp = convertTemperatureFNC(value);

    return `${temp ? temp.toFixed(2) : ''} ${tmpType ? tmpType.unit : ''}`;
}

const isNumber = (value): boolean => {
    try {
        Number.parseInt(value);
        return true;
    } catch (error) {
        return false;
    }
}
