import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Utils } from '../utils/Utils';

import { ApiService } from './api.base.service';

import { Alarm } from '../models/alarm';

@Injectable({ providedIn: 'root' })
export class AlarmService {
  private alarmsURL = "alarms";
  private trackerAlarmsURL = "trackerAlarms";

  constructor(private api: ApiService) {
  }

  getAlarms(): Observable<Alarm[]> {
    return this.api.httpGET<any>(this.alarmsURL, 'alarms').pipe(
      map(alarms => {
        alarms.forEach(element => {
          element.alarmTimeDate = Utils.convertStringToDate(element.alarmTime);
        });
        return alarms;
      })
    );
  }

  getTrackerAlarms(tracker_id, limit: number = 10, filterStartDate: String = '', filterEndDate: String = '', shippingId: String = ''): Observable<any> {
    let URL = `${this.trackerAlarmsURL}/${tracker_id}?limit=${limit}&filterStartDate=${filterStartDate}&filterEndDate=${filterEndDate}&shippingId=${shippingId}`;

    return this.api.httpGET<any>(URL, 'trackerAlarms');
  }
}