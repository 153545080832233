import { Component, Input } from '@angular/core';

import { SubscribeBase } from '../../bases/subscribe.base';

@Component({
    selector: 'ac-confirm-toast',
    templateUrl: './ac-confirm-toast.component.html',
    styleUrls: ['./ac-confirm-toast.component.css']
})
export class ConfirmToastAC extends SubscribeBase {
    @Input() message: any;
    @Input() messageData: any;
    @Input() parent: any;

    type: string;

    ngOnInit() { this.init() }
    ngOnChanges() { this.init() }

    ngOnDestroy(){
        this.breakSubscribes();
    }

    init() {
        this.type = this.messageData.type;

        if (!this.parent)
            return;

        const parentOnCLose=this.parent['onClose'];
        if(parentOnCLose){
            this.breakSubscribes();
            this.addSubscribe('PARENT_ON_CLOSE',parentOnCLose, ()=>this.onPressButton('onReject'))
        }
    }

    breakSubscribes(){
        this.allitemsUnsubscribeAndRemove();
    }

    onPressButton(type) {
        if (!this.messageData)
            return;

        if (this.messageData[type])
            this.messageData[type]();
    }
}