import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { ApiService } from './api.base.service';

import { Project } from '../models/project';
import { Rssifilterconfig } from '../models/rssifilterconfig';

@Injectable({ providedIn: 'root' })
export class ProjectService {
  private baseURL = 'projects';
  private saveBaseURL = 'saveProject';
  private deleteProjectURL = 'deleteProject';
  private getRSSIFilter = 'rssiFilterConfig';
  private saveRSSIFilterconfigBaseURL = 'rssiFilterConfig';

  constructor(private api: ApiService) {
  }

  getProjects(): Observable<Project[]> {
    return this.api.httpGET<any[]>(this.baseURL, 'projects');
  }
  getProjects_(clientId: number): Observable<Project[]> {
    const parameterValue = 'clientId=' + clientId;
    return this.api.httpGET<any[]>(`${this.baseURL}?${parameterValue}`, 'projects');
  }

  getProjectClient(clientId: number): Observable<any[]> {
    const parameterValue = 'clientId=' + clientId;
    return this.api.httpGET<any[]>(`${this.baseURL}?${parameterValue}`, 'projects');
}

  getProject(projectId: number): Observable<Project> {
    if (projectId === 0) {
      return of(this.newProject());
    }

    return this.api.httpGET(this.baseURL + "/" + projectId, 'project');
  }
  newProject(): Project {
    return {
      id: 0,
      name: "",
      timezone: "",
      status: 0
    };
  }

  saveProject(project: Project): Observable<Project> {
    return this.api.httpPOST<any>(this.saveBaseURL, project);
  }

  deleteProject(projectId) {
    return this.api.httpDELETE<any>(`${this.deleteProjectURL}/${projectId}`);
  }

  getRSSIFilterconfig(projectId: number): Observable<Project>{
    return this.api.httpGET(this.getRSSIFilter + "?projectId=" + projectId, 'firmwares');
  }

  saveRSSIFilterconfig(firmwares :Rssifilterconfig){
    return this.api.httpPUT<any>(this.saveRSSIFilterconfigBaseURL, firmwares);
  }
}
