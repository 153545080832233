import { USER_TYPES, CLIENT_TYPES } from '../../authorization/authentication.service';

export const chekcPermissionFunc = (currentUser: any, minUserType: string): boolean => {
    try {
        if (!currentUser || !currentUser.userTypes || !Array.isArray(currentUser.userTypes) || currentUser.userTypes.length === 0) {
            return false;
        }
        else {
            const { userTypes } = currentUser;

            if (minUserType === USER_TYPES.SUPER_ADMIN && userTypes.includes(USER_TYPES.SUPER_ADMIN)) {
                return true;
            }
            else if (minUserType === USER_TYPES.ADMIN && (userTypes.includes(USER_TYPES.SUPER_ADMIN) || userTypes.includes(USER_TYPES.ADMIN))) {
                return true;
            }
            else if (minUserType === USER_TYPES.VIEWER && (userTypes.includes(USER_TYPES.SUPER_ADMIN) || userTypes.includes(USER_TYPES.ADMIN) || userTypes.includes(USER_TYPES.VIEWER))) {
                return true;
            }
            else {
                return false;
            }
        }
    } catch (error) {
        return false;
    }
}

export const checkClientTypePermission = (currentUser: any, minClientType: number): boolean => {
    try {
        if (!currentUser || currentUser.clientType === undefined || currentUser.clientType === null) {
            return false;
        }
        else {
            const { clientType } = currentUser;

            if (minClientType === CLIENT_TYPES.ALL_ACCESS && clientType === CLIENT_TYPES.ALL_ACCESS) {
                return true;
            }
            else if (minClientType === CLIENT_TYPES.ASSET_TRACKING && (clientType === CLIENT_TYPES.ALL_ACCESS || clientType === CLIENT_TYPES.ASSET_TRACKING)) {
                return true;
            }
            else if (minClientType === CLIENT_TYPES.SHIPMENT && (clientType === CLIENT_TYPES.ALL_ACCESS || clientType === CLIENT_TYPES.SHIPMENT)) {
                return true;
            }
            else {
                return false;
            }
        }
    } catch (error) {
        return false;
    }
}