import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import md5 from 'md5';

import { RegistrationService } from './register.service';
import { MessageService } from 'primeng/api';

export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      return;
    }

    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  }
}

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  constructor(private formBuilder: FormBuilder, private registrationService: RegistrationService, private router: Router, private messageService: MessageService) { }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(24)]],
      confirmpassword: ['', Validators.required]
    }, {
        validator: MustMatch('password', 'confirmpassword')
      }
    );
  }

  get f() { return this.registerForm.controls; }

  registerForm: FormGroup;
  submitted = false;

  signUp() {
    this.submitted = true;
    this.messageService.clear();
    
    if (this.registerForm.invalid) {
      return;
    }

    const hashedPassword = md5(this.f.password.value);

    this.registrationService.register(this.f.firstName.value, this.f.lastName.value, this.f.email.value, hashedPassword)
      .subscribe(
        data => {
          this.router.navigate(['/login']);
        },
        error => {
          if (error === "Conflict")
            this.messageService.add({ severity: 'error', summary: 'Registration Error', detail: "Username is already taken" });
          else
            this.messageService.add({ severity: 'error', summary: 'Registration Error', detail: error.message });
        });
  }

}
