import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map, catchError } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { Observable, throwError } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ApiService {
    private baseURL = environment.baseUrl;

    constructor(private http: HttpClient) {
    }

    httpGET<T>(URL, responseDataAccessor?: string): Observable<T> {
        return this.http.get<T>(`${this.baseURL}${URL}`)
            .pipe(
                map(data => this.handlePipe(data, responseDataAccessor)),
                catchError(this.handleError)
            );
    }

    httpPOST<T>(URL, requestBody: any, responseDataAccessor?: string): Observable<T> {
        return this.http.post<T>(`${this.baseURL}${URL}`, requestBody)
            .pipe(
                map(data => this.handlePipe(data, responseDataAccessor)),
                catchError(this.handleError)
            );
    }

    httpPUT<T>(URL, requestBody: any, responseDataAccessor?: string): Observable<T> {
        return this.http.put<T>(`${this.baseURL}${URL}`, requestBody ? requestBody : null)
            .pipe(
                map(data => this.handlePipe(data, responseDataAccessor)),
                catchError(this.handleError)
            );
    }

    httpDELETE<T>(URL, requestBody?: any): Observable<T> {
        return this.http.delete<T>(`${this.baseURL}${URL}`)
            .pipe(
                catchError(this.handleError)
            );
    }

    private handlePipe(data: any, responseDataAccessor?: string) {
        return responseDataAccessor ? data[responseDataAccessor] : data;
    }

    private handleError(err) {
        console.error(err);
        // in a real world app, we may send the server to some remote logging infrastructure
        // instead of just logging it to the console
        let errorMessage: string;
        if (err instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            errorMessage = `An error occurred: ${err.error}`;
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            errorMessage = err;
        }
        return throwError(errorMessage);
    }
}
