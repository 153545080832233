import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { ApiService } from './api.base.service';

import { Beacon } from '../models/beacon';

@Injectable({ providedIn: 'root' })
export class BeaconsService {
  private beaconsBaseURL = 'beacons';
  private saveBaseURL = 'beacon';
  private deleteBaseURL = 'deleteBeacon/';


  constructor(private api: ApiService) { }

  getBeacons(): Observable<Beacon[]> {
    return this.api.httpGET<any[]>(this.beaconsBaseURL, 'beacons');
  }

  getBeacons_(clientId: number): Observable<Beacon[]> {
    const parameterValue = 'clientId=' + clientId;
    return this.api.httpGET<any[]>(`${this.beaconsBaseURL}?${parameterValue}`, 'beacons');
  }


  getBeacon(beaconId: number): Observable<Beacon> {
    if (beaconId === 0) {
      return of(this.newBeacon());
    }

    return this.api.httpGET<any>(this.beaconsBaseURL + '/' + beaconId, 'beacon');
  }

  saveBeacon(beacon: Beacon): Observable<Beacon> {
    return this.api.httpPOST<any>(this.saveBaseURL, beacon);
  }

  deleteBeacon(beaconId) {
    return this.api.httpDELETE<any>(`${this.deleteBaseURL}${beaconId}`, 'project');
  }

  newBeacon(): Beacon {
    return {
      project: 0,
      client: 0,
      project_id: 0,
      clientId: 0,
      id: 0,
      device_serial: '',
      mac: '',
      uuid: '',
      major: '',
      minor: '',
      latitude: 0,
      longitude: 0,
      type: 0
    };
  }
}
