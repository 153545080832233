import { Component, Input } from '@angular/core';
import {Router} from '@angular/router';
import { convertFullDateFromUTCToUserTimezoneTEXT } from '../../base-functions/as-timezones.base-function';

@Component({
    selector: 'ac-custom-toaster',
    templateUrl: './custom-toaster.component.html',
    styleUrls: ['./custom-toaster.component.css']
})
export class CustomToaster {
    @Input() item: any = {}

    alarm_type:string;

    summary: string = '';
    detail: string = '';
    iconURL: string = '';

    constructor(private router:Router){}

    ngOnInit() { this.init(); }
    ngOnChanges() { this.init(); }

    init() {
        this.alarm_type = this.item.alarm_type;

        let alarmTypeStr = this.alarm_type;

        if(alarmTypeStr === 'GEOFENCE_IN') {
            alarmTypeStr = 'Goods arrived to destination';
        }
        else if(alarmTypeStr === 'GEOFENCE_OUT') {
            alarmTypeStr = 'Goods left the origin';
        }

        this.summary = this.item.trackerName + ' Alarm';
        this.detail = `${alarmTypeStr} ${this.item.tracker_ts ? `@${convertFullDateFromUTCToUserTimezoneTEXT(this.item.tracker_ts)}` : '' }`;
        this.iconURL = `assets/png-icons/TRACKER_ALERTS/${this.alarm_type}.png`;

    }

    onClickAlarm(){
        this.router.navigateByUrl(`trackers/${this.item.tracker.id}/data/realtime`)
    }
}