import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import * as jwt_decode from 'jwt-decode';

import { AuthenticationService } from './authentication.service';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        let currentUser = this.authenticationService.currentUserValue;

        request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
        request = request.clone({ headers: request.headers.set('Accept', 'application/json') });

        if (currentUser && currentUser.token) {
            request = request.clone({ headers: request.headers.set('Authorization', `${currentUser.token}`) });
        }


        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    if (currentUser && event && event.body && event.body.token) {
                        var tokenInfo = jwt_decode(event.body.token);
                        currentUser.token = event.body.token;
                        currentUser.tokenValidUntil = (tokenInfo.exp) * 1000;
                    }
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                let data = {};
                
                
                return throwError(error);
            })
        );
    }
}