import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';

import { UserService } from './user.service';

@Injectable({
    providedIn: 'root'
})
export class UsersResolver implements Resolve<any[]> {

    constructor(private serviceUser: UserService) { }

    resolve(): Observable<any[]> {
        return this.serviceUser.getUsers(0);
    }
}

@Injectable({
    providedIn: 'root'
})
export class ClientUsersResolver implements Resolve<any> {

    constructor(private serviceUser: UserService) { }

    resolve(route: ActivatedRouteSnapshot, ): Observable<any> {
        const id = route.paramMap.get('clientId');
        if (isNaN(+id)) {
            const message = `Project id was not a number: ${id}`;
            console.error(message);
            return Observable.throw(message);
        }

        return this.serviceUser.getClientUsers(id);

    }
}


@Injectable({
    providedIn: 'root'
})
export class UserResolver implements Resolve<any> {

    constructor(private serviceUser: UserService) { }

    resolve(route: ActivatedRouteSnapshot, ): Observable<any> {
        const id = route.paramMap.get('userId');
        if (isNaN(+id)) {
            const message = `Project id was not a number: ${id}`;
            console.error(message);
            return Observable.throw(message);
        }

        return this.serviceUser.getUser(id);

    }
}