import { Directive, Injector, ElementRef, TemplateRef, ViewContainerRef } from '@angular/core';

import { PermissionsDrirectivesBase } from './base';

import { USER_TYPES } from 'src/app/authorization/authentication.service';

@Directive({
    selector: '[SUPER_ADMIN]'
})
export class DirectiveSuperAdminDirectiveAC extends PermissionsDrirectivesBase {

    constructor(
        injector: Injector,
        element: ElementRef,
        templateRef: TemplateRef<any>,
        viewContainer: ViewContainerRef
    ) {
        super(injector, element, templateRef, viewContainer, USER_TYPES.SUPER_ADMIN);
    }
}