import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { Asset } from '../models/asset';
import { AssetsService } from './asset.service';

@Injectable({
  providedIn: 'root'
})
export class AssetsResolver implements Resolve<Asset[]> {

  constructor(private assetService: AssetsService) { }

  resolve(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<Asset[]> {

    return this.assetService.getAssets()
      .pipe(
        map(assets => {
          if (assets)
            return assets;
        }),
        catchError(error => {
          const message = `Retrieval error: ${error}`;
          console.error(message);
          return Observable.throw(error);
        })
      );
  }

}

@Injectable({
  providedIn: 'root'
})
export class AssetResolver implements Resolve<Asset> {

  constructor(private assetService: AssetsService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<Asset> {

    const id = route.paramMap.get('assetId');
    if (isNaN(+id)) {
      const message = `Asset id was not a number: ${id}`;
      console.error(message);
      return Observable.throw(message);
    }

    return this.assetService.getAsset(+id)
      .pipe(
        map(asset => {
          if (asset)
            return asset;
        }),
        catchError(error => {
          const message = `Retrieval error: ${error}`;
          console.error(message);
          return Observable.throw(error);
        })
      );
  }
}

@Injectable({
  providedIn: 'root'
})
export class AssetRealtimeResolver implements Resolve<Asset> {

  constructor(private assetService: AssetsService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<Asset> {

    const id = route.paramMap.get('assetId');
    if (isNaN(+id)) {
      const message = `Asset id was not a number: ${id}`;
      console.error(message);
      return Observable.throw(message);
    }

    return this.assetService.getAsset(+id)
      .pipe(
        map(asset => {
          if (asset)
            return asset;
        }),
        catchError(error => {
          const message = `Retrieval error: ${error}`;
          console.error(message);
          return Observable.throw(error);
        })
      );
  }
}
