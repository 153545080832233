import { Component, Input } from '@angular/core';

import { GeocodeService } from 'src/app/shared/services/geocode.service';

@Component({
    selector: 'ac-tracker-alarm-item',
    templateUrl: './tracker-alarm-item.component.html',
    styleUrls: ['./tracker-alarm-item.component.css']
})
export class TrackerAlarmItemAC {
    @Input() item: any = {};
    constructor(private serviceGeocode: GeocodeService) { }

    ngOnInit() { this.init() }
    ngOnChanges() { this.init() }

    async init() {
        if (!this.item)
            return;

        const alarm_type = String(this.item.alarm_type).toString();

        let alarmTypeStr = alarm_type;

        if (alarmTypeStr === 'GEOFENCE_IN') {
            alarmTypeStr = 'Goods arrived to destination';
        }
        else if (alarmTypeStr === 'GEOFENCE_OUT') {
            alarmTypeStr = 'Goods left the origin';
        }

        this.item.ICON = `assets/png-icons/TRACKER_ALERTS/${alarm_type}.png`;
        this.item.alarm_type_TEXT = this.capitalize(alarmTypeStr.replace(/_/g, ' '));
        /*
                if (!this.item.trackerActivity){
                    this.item.geoAdress = 'Unknown';
                    return;
                }
         */
        /*Comment the code of GeoAddress for not show the Address of tracker.*/
        //const { gpsLatitude, gpsLongitude } = this.item.trackerActivity;
        //this.item.geoAdress = await this.serviceGeocode.reverseGeocodeAddress(gpsLatitude, gpsLongitude).toPromise();
    }

    capitalize = (txt: String) => {
        return txt.split(' ')
            .map(k => {
                return k.charAt(0).toUpperCase() + k.slice(1).toLocaleLowerCase();
            })
            .toString()
            .replace(/,/g, ' ');
    }
}
