import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { ApiService } from './api.base.service';

import { Asset } from '../models/asset';
import {TrackerBriefData} from "../models/trackerBriefData";
import { TemperatureResult } from '../models/temperatureResult';

@Injectable({ providedIn: 'root' })
export class AssetsService {
  private assetsBaseURL = "assets";
  private saveAssetURL = "saveAsset";
  private deleteAssetURL = 'deleteAsset/';

  private getBriefDataURL = "trackerBriefData";
  private getTemperatureDataURL = 'assetsTemperatureData';
  private assetActivitiesURL = 'assetActivities';

  constructor(private api: ApiService) {
  }

  getAssets(): Observable<Asset[]> {
    return this.api.httpGET<any>(this.assetsBaseURL, 'assets');
  }
  getAssets_(clientId: number): Observable<Asset[]> {
    const parameterValue = 'clientId=' + clientId;
    return this.api.httpGET<any[]>(`${this.assetsBaseURL}?${parameterValue}`, 'assets');
  }

  getAsset(assetId: number): Observable<Asset> {
    if (assetId === 0) {
      return of(this.newAsset());
    }

    return this.api.httpGET<any>(this.assetsBaseURL + "/" + assetId, 'asset');
  }

  saveAsset(asset: Asset): Observable<any> {
    return this.api.httpPOST<any>(this.saveAssetURL, asset);
  }

  deleteAsset(assetId): Observable<any> {
    return this.api.httpDELETE<any>(`${this.deleteAssetURL}${assetId}`);
  }

  newAsset(): Asset {
    return {
      id: 0,
      assetIdentifier: '',
      locationType: 0,
      fixedLatitude: null,
      fixedLongitude: null,
      projectId: null,
      tracker: null,
      trackerId: null,
      beaconId: null,
      beacon: null,
      // project: null,
      clientId: null
    };
  }

  /*For get Tracker Brief data in Asset View page*/

  getTrackerBriefData(trackerId: number, filterStartDate: string = '', filterEndDate: string = ''): Observable<TrackerBriefData> {
    let URL = this.getBriefDataURL + "/" + trackerId + `?filterStartDate=${filterStartDate}&filterEndDate=${filterEndDate}`;

    return this.api.httpGET<any>(URL, 'trackerData');
  }
/*For get assets Temperature data*/

  getassetTemperatureData(assetId: number, filterStartDate: string = '', filterEndDate: string = '', shippingId: String = ''): Observable<TemperatureResult[]> {
    let URL = `${this.getTemperatureDataURL}/${assetId}?filterStartDate=${filterStartDate}&filterEndDate=${filterEndDate}&shippingId=${shippingId}`;

    return this.api.httpGET<any>(URL, 'temperatureData');
  }

  /*For get assets Activities*/
  getassetActivitiesByTrackerIDWithStartEndDate(assetId: number, filterStartDate: string = '', filterEndDate: string = '', shippingId: String = ''): Observable<any[]> {
    let URL = `${this.assetActivitiesURL}/${assetId}?filterStartDate=${filterStartDate}&filterEndDate=${filterEndDate}&shippingId=${shippingId}`;

    return this.api.httpGET<any[]>(URL, 'assetActivities');
}
}
