import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { ApiService } from './api.base.service';

import { People } from '../models/people';

@Injectable({ providedIn: 'root' })
export class PeoplesService {
  private baseURL = 'subjects';
  private saveBaseURL = 'subjects';
  private deletePeopleURL = 'subjects';

  constructor(private api: ApiService) {
  }

  getPeoples(): Observable<People[]> {
    return this.api.httpGET<any[]>(this.baseURL, 'subjects');
  }

  getPeople(projectId: any): Observable<People> {
    if (projectId === 0) {
      return of(this.newPeople());
    }
    return this.api.httpGET(this.baseURL + '?personal_id=' + projectId, 'subjects');
  }
  newPeople(): People {
    return {
      id: 0,
      name: '',
      surname: '',
      personal_id: null,
      phone: null,
      email: null,
      birth_date: null,
      gender : null,
      country : null,
      project_id: null,
      associated_bracelet_id: 0,
    };
  }

  savePeople(people: People): Observable<People> {
    return this.api.httpPOST<any>(this.saveBaseURL, people);
  }

  editPeople(people: any): Observable<People> {
    return this.api.httpPUT<any>(this.saveBaseURL , people);
  }

  deletePeople(projectId) {
    return this.api.httpDELETE<any>(`${this.deletePeopleURL}/${projectId}`);
  }
}
