import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';

import {ApiService} from './api.base.service';

import {Project} from '../models/project';

@Injectable({providedIn: 'root'})
export class DashboardService {
  private dashboardTrackersURL = 'dashboardTrackers';
  private resetTrackerURL = 'resetTracker';
  private baseURL = 'projects';
  private braceletDashboardURL = 'braceletDashboard';


  constructor(private api: ApiService) {
  }

  getDashboardTrackers(): Observable<any[]> {
    return this.api.httpGET<any[]>(this.dashboardTrackersURL, 'trackers');
  }

  resetTracker(trackerId): Observable<any> {
    return this.api.httpPUT<any>(`${this.resetTrackerURL}/${trackerId}`, {});
  }

  getProjects(): Observable<Project[]> {
    return this.api.httpGET<any[]>(this.baseURL, 'projects');
  }

  getBraceletDashboard(scope,projectId): Observable<any> {
    return this.api.httpGET<any[]>(`${this.braceletDashboardURL}?scope=${scope}&project_ids=${projectId}`, 'dashboardData');
  }
}


