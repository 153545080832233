import { Component, OnInit, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import md5 from 'md5';
import { MessageService } from 'primeng/api';
import { first } from 'rxjs/operators';

import { AuthenticationService } from '../../authorization/authentication.service';
import { validatorEmailFNC } from '../../shared/custom-form-validators';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    displayForgotPasswordDialog: boolean = false;
    loginForm: FormGroup;
    forgotPasswordForm: FormGroup;
    submitted = false;
    forgotPasswordSubmitted = false;
    returnUrl: string;
    isLoggedIn: boolean = false;
    clientype;
    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private zone: NgZone,
        private router: Router,
        private authenticationService: AuthenticationService,
        private messageService: MessageService
    ) {
        // redirect to home if already logged in
        if (this.authenticationService.currentUserValue) {

            this.isLoggedIn = true;

            let clientType = this.authenticationService.currentUserValue['clientType'];

            // Condition for redirect to BraceletDashboard page if clientType=4.(it is for temperory purpose)
            if (clientType === 4) {
                this.zone.run(() => {
                    this.router.navigate(['/braceletDashboard']);
                });
            } else {
                this.zone.run(() => {
                    this.router.navigate(['/dashboard']);
                });
            }

        }
    }

    ngOnInit() {
        const remember_me = this.authenticationService.getLoggedInUserName();

        this.loginForm = this.formBuilder.group({
            username: [remember_me, Validators.required],
            password: ['', Validators.required],
            remember_me: [remember_me ? true : false]
        });

        this.forgotPasswordForm = this.formBuilder.group({
            forgotPasswordEmail: [remember_me, [Validators.required, validatorEmailFNC()]]
        });

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/dashboard';
    }

    showDialog() {
        this.displayForgotPasswordDialog = true;
    }

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }
    get forgotPassf() { return this.forgotPasswordForm.controls; }

    Login() {
        this.submitted = true;
        this.messageService.clear();

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

        const { username, password, remember_me } = this.loginForm.value;

        const hashedPassword = md5(password);

        this.authenticationService.login(username, hashedPassword)
            .pipe(first())
            .subscribe(
                data => {
                    if (remember_me) {
                        this.authenticationService.saveLogedInUserName(username);
                    }
                    else {
                        this.authenticationService.removeLoggedInUserName();
                    }

                    this.isLoggedIn = true;

                    this.zone.run(() => {
                        this.router.navigate([this.returnUrl]);
                    });
                },
                error => {
                    if (error === "Not Found") {
                        this.messageService.add({ severity: 'error', summary: 'Login Error', detail: "Username or password is not recognized." });
                    }
                    else {
                        this.messageService.add({ severity: 'error', summary: 'Login Error', detail: error.message });
                    }
                });
    }

    CancelForgotPassword() {
        this.displayForgotPasswordDialog = false;
    }

    ForgotPassword() {
        this.forgotPasswordSubmitted = true;

        // stop here if form is invalid
        if (this.forgotPasswordForm.invalid) {
            return;
        }

        const reqBody = {
            email: this.forgotPasswordForm.value.forgotPasswordEmail
        };

        this.authenticationService.forgotPassword(reqBody)
            .subscribe(
                () => {
                    this.messageService.clear('MAIN_TOAST');
                    this.messageService.add({ key: 'MAIN_TOAST', severity: 'success', summary: 'Forgot Password', detail: "E-mail has been sent to reset your password." });
                    this.displayForgotPasswordDialog = false;
                },
                error => {
                    this.messageService.clear('MAIN_TOAST');
                    this.messageService.add({ key: 'MAIN_TOAST', severity: 'error', summary: 'Forgot Password', detail: error.message });
                });
    }

}
