import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

import { Observable } from 'rxjs';

import { OrderService } from './order.service';

@Injectable({
    providedIn: 'root'
})
export class OrdersResolver implements Resolve<any[]> {

    constructor(private serviceOrder: OrderService) { }

    resolve(): Observable<any[]> {
        return this.serviceOrder.listOrders();
    }
}

@Injectable({
    providedIn: 'root'
})
export class OrderResolver implements Resolve<any> {

    constructor(private serviceOrder: OrderService) { }

    resolve(route: ActivatedRouteSnapshot, ): Observable<any> {
        const orderId = route.paramMap.get('orderId');
        if (isNaN(+orderId)) {
            const message = `orderId was not a number: ${orderId}`;
            console.error(message);
            return Observable.throw(message);
        }

        return this.serviceOrder.showOrder(orderId);
    }
}