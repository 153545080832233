import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { ApiService } from './api.base.service';

@Injectable({ providedIn: 'root' })
export class UserService {
    private getUsersURL = "users";
    private getUserURL = "users/"
    private saveUserURL = "saveUser";
    private deleteUserURL = "deleteUser/";

    constructor(private api: ApiService) { }

    getUsers(offset: number): Observable<any[]> {
        let URL = `${this.getUsersURL}?offset=${offset ? offset : 0}`;
        return this.api.httpGET<any[]>(URL, 'users');
    }
    getUsers_(clientId: number): Observable<any[]> {
        const parameterValue = 'clientId=' + clientId;
        return this.api.httpGET<any[]>(`${this.getUsersURL}?${parameterValue}`, 'users');
    }

    getClientUsers(clientId: String): Observable<any[]> {
        const URL = `${this.getUsersURL}?clientId=${clientId}`;
        return this.api.httpGET<any[]>(URL, 'users');
    }

    getUser(userId): Observable<any> {
        if (userId == '0')
            return of({});

        let URL = `${this.getUserURL}${userId}`;

        return this.api.httpGET<any>(URL, 'user');
    }

    saveUser(data: any): Observable<any> {
        return this.api.httpPOST<any>(this.saveUserURL, data);
    }

    deleteUser(user: any) {
        const URL = `${this.deleteUserURL}${user.id}`;
        return this.api.httpDELETE(URL);
    }
}