import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { ApiService } from './api.base.service';

import { Tracker } from '../models/tracker';

import { TrackerBriefData } from '../models/trackerBriefData';
import { TemperatureResult } from '../models/temperatureResult';
import { TrackerParameters } from '../models/trackerParameters';
import { HumidityResult } from '../models/HumidityResult';

@Injectable({ providedIn: 'root' })
export class TrackerService {
  private trackersBaseURL = "trackers";
  private registerURL = "registerTracker";
  private saveConfigURL = "saveTrackerConfig";
  private saveBulkConfigURL = "bulkTrackerConfig";
  private saveTrackerURL = "saveTracker";
  private getBriefDataURL = "trackerBriefData";
  private getTemperatureDataURL = "trackerTemperatureData";
  private getParametersUL = "trackerParameters";
  private getProjectTrackersURL = "getProjectTrackers";
  private deleteTrackerURL = 'deleteTracker/';
  private associateURL = "associateTracker";
  private checkTrackerWithQRURL = "checkTrackerWithQR";
  private checkDeliveryNoteURL = "checkDeliveryNote";
  private getHumidityDataURL = 'trackerHumidityData';

  constructor(private api: ApiService) { }

  getTrackers(): Observable<Tracker[]> {
    return this.api.httpGET<any[]>(this.trackersBaseURL, 'trackers');
  }

  getTrackers_(clientId: number): Observable<Tracker[]> {
    const parameterValue = 'clientId=' + clientId;
    return this.api.httpGET<any[]>(`${this.trackersBaseURL}?${parameterValue}`, 'trackers');
  }

  registerTracker(caseQr: string): Observable<string> {
    return this.api.httpPOST<any>(this.registerURL, { caseQr: caseQr });
  }

  getTracker(trackerId: number): Observable<Tracker> {
    return this.api.httpGET<any>(this.trackersBaseURL + "/" + trackerId, 'tracker');
  }

  saveTrackerConfig(tracker: Tracker): Observable<Tracker> {
    return this.api.httpPOST<any>(this.saveConfigURL, tracker);
  }

  saveBulkTrackerConfig(bulkConfiguration: any): Observable<any> {
    return this.api.httpPOST<any>(this.saveBulkConfigURL, bulkConfiguration);
  }

  saveTracker(tracker: any): Observable<any> {
    return this.api.httpPOST<any>(this.saveTrackerURL, tracker);
  }

  getTrackerBriefData(trackerId: number, filterStartDate: string = '', filterEndDate: string = ''): Observable<TrackerBriefData> {
    let URL = this.getBriefDataURL + "/" + trackerId + `?filterStartDate=${filterStartDate}&filterEndDate=${filterEndDate}`;

    return this.api.httpGET<any>(URL, 'trackerData');
  }

  getTrackerTemperatureData(trackerId: number, filterStartDate: string = '', filterEndDate: string = '', shippingId: String = ''): Observable<TemperatureResult[]> {
    let URL = `${this.getTemperatureDataURL}/${trackerId}?filterStartDate=${filterStartDate}&filterEndDate=${filterEndDate}&shippingId=${shippingId}`;

    return this.api.httpGET<any>(URL, 'temperatureData');
  }


  getTrackerHumidityData(trackerId: number, filterStartDate: string = '', filterEndDate: string = '', shippingId: String = ''): Observable<HumidityResult[]> {
    let URL = `${this.getHumidityDataURL}/${trackerId}?filterStartDate=${filterStartDate}&filterEndDate=${filterEndDate}&shippingId=${shippingId}`;

    return this.api.httpGET<any>(URL, 'humidityData');
  }


  getTrackerParameters(): Observable<TrackerParameters> {
    return this.api.httpGET<any>(this.getParametersUL)
      .pipe(
        map(data => {
          if (data) {
            let trackerParameters: TrackerParameters = {
              trackerBlePowers: data.trackerBlePowers,
              trackerLocationModes: data.trackerLocationModes,
              trackerTechnologyPriorities: data.trackerTechnologyPriorities,
              trackerBandGsms: data.trackerBandGsms,
              trackerBandLtem1s: data.trackerBandLtem1s,
              trackerBandNbs: data.trackerBandNb
            };

            return trackerParameters;
          }
        })
      );
  }

  getProjectTrackers(projectId: number): Observable<Tracker[]> {
    return this.api.httpGET<any>(this.getProjectTrackersURL + "/" + projectId, 'projectTrackers');
  }

  deleteTracker(trackerId) {
    return this.api.httpDELETE<any>(`${this.deleteTrackerURL}${trackerId}`, 'project');
  }

  associateTracker(associationJson: any): Observable<string> {
    return this.api.httpPOST<any>(this.associateURL, associationJson);
  }

  checkTrackerWithQR(qrCode: string): Observable<Tracker> {
    return this.api.httpGET<any>(this.checkTrackerWithQRURL + `?trackerQRCode=${qrCode}`, 'tracker');
  }

  checkDeliveryNote(shipmentName: string): Observable<any> {
    return this.api.httpGET<any>(this.checkDeliveryNoteURL + `?shipmentName=${shipmentName}`, 'shipment');
  }
}
