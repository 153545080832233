import * as moment from 'moment-timezone';
import { KEY_LABEL_PAIR } from './KEY_VALUE_PAIR.interface';
import { authUserTimezoneKey } from '../../authorization/authentication.service';

const formatFullDate = 'YYYY-MM-DD HH:mm:ss';
const formatFullDateMilli = 'YYYY-MM-DD HH:mm:ss.SSS';
const formatOnlyDate = 'YYYY-MM-DD';
const formatOnlyTime = 'HH:mm:ss';

export const getTimezonesFNC = (): KEY_LABEL_PAIR[] => {
    return moment.tz.names().map(k => { return { key: k, label: k.replace('/', ' / ') } })
}

export const findTimezoneByTimezoneKeyFNC = (key: string): KEY_LABEL_PAIR => {
    const timezones = getTimezonesFNC();
    const result = timezones.find(k => k.key == key);
    return result;
}

export const convertFullDateFromUTCToUserTimezoneTEXT = (value: string): string => {
    try {
        if(value === null)
            return 'N/A';

        if (!authUserTimezoneKey || authUserTimezoneKey == '')
            return moment(value).format(formatFullDate);

        return moment(value).tz(authUserTimezoneKey).format(formatFullDate);
    } catch (error) {
        return '0000-00-00 00:00:00'
    }
}

export const convertFullDateMilliFromUTCToUserTimezoneTEXT = (value: string): string => {
  try {
    if(value === null)
      return 'N/A';

    if (!authUserTimezoneKey || authUserTimezoneKey == '')
      return moment(value).format(formatFullDateMilli);

    return moment(value).tz(authUserTimezoneKey).format(formatFullDateMilli);
  } catch (error) {
    return '0000-00-00 00:00:00'
  }
}
export const convertOnlyDateFromUTCToUserTimezoneTEXT = (value: string): string => {
    try {
        if(value === null)
            return 'N/A';

        if (!authUserTimezoneKey || authUserTimezoneKey == '')
            return moment(value).format(formatOnlyDate);

        return moment(value).tz(authUserTimezoneKey).format(formatOnlyDate);
    } catch (error) {
        return '0000-00-00';
    }
}
export const convertOnlyTimeFromUTCToUserTimezoneTEXT = (value: string): string => {
    try {
        if(value === null)
            return 'N/A';

        if (!authUserTimezoneKey || authUserTimezoneKey == '')
            return moment(value).format(formatOnlyTime);

        return moment(value).tz(authUserTimezoneKey).format(formatOnlyTime);
    } catch (error) {
        return '00:00:00';
    }
}
