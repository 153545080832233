import { Component, OnInit, HostListener } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { MessageService } from 'primeng/api';

import { Project } from './../../shared/models/project';
import { Tracker } from './../../shared/models/tracker';
import { TrackerService } from '../../shared/services/tracker.service';
import { ProjectService } from '../../shared/services/project.service';

@Component({
    selector: 'association-tool',
    templateUrl: './association-tool.component.html',
    styleUrls: ['./association-tool.component.css']
})
export class AssociationToolComponent implements OnInit {

    associateTrackerForm: FormGroup;
    projects: Project[] = [];
    submitted = false;
    scannerEntry = '';

    trackerObj: Tracker = null;
    shipmentObj = null;

    get f() { return this.associateTrackerForm.controls; }

    constructor(
        private formBuilder: FormBuilder,
        private trackerService: TrackerService,
        private messageService: MessageService,
        private serviceProject: ProjectService
    ) { }

    ngOnInit() {

        this.associateTrackerForm = this.formBuilder.group({
            trackerQRCode: ['', Validators.required],
            deliveryNoteCode: ['', Validators.required],
            project: [null, Validators.required]
        });

        this.loadProjects();
    }

    async loadProjects() {

        try {
            this.projects = await this.serviceProject.getProjects().toPromise();
        }
        catch (error) {
            this.messageService.clear('MAIN_TOAST');
            this.messageService.add({ key: 'MAIN_TOAST', severity: 'error', summary: 'Association Tool', detail: 'Could not fetch projects!' });

            this.projects = [];
        }

        this.associateTrackerForm.controls['project'].disable();
    }

    @HostListener('document:keypress', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {

        let eventKey = event.key;

        if (eventKey === 'Enter') {
            this.checkScannedValue();
        }
        else {

            if (event.charCode === 92) {
                //Replace backslash with pipe so it won't corrupt the input string
                eventKey = '-';
            }

            this.scannerEntry += eventKey.toUpperCase();
        }
    }

    async checkScannedValue() {

        const scannedValue = this.scannerEntry;

        try {
            const tracker = await this.trackerService.checkTrackerWithQR(scannedValue).toPromise();

            if(tracker) {

                this.trackerObj = tracker;

                this.arrangeProject();

                this.associateTrackerForm.controls['trackerQRCode'].setValue(tracker.qr);

                this.messageService.clear('MAIN_TOAST');
                this.messageService.add({ key: 'MAIN_TOAST', severity: 'success', summary: 'Association Tool', detail: `Found the Tracker with code "${scannedValue}" successfully!` });
            }
            else {
                const shipment = await this.trackerService.checkDeliveryNote(scannedValue).toPromise();

                if(shipment) {
                    
                    this.shipmentObj = shipment;

                    this.arrangeProject();
                    
                    this.associateTrackerForm.controls['deliveryNoteCode'].setValue(shipment.name);

                    this.messageService.clear('MAIN_TOAST');
                    this.messageService.add({ key: 'MAIN_TOAST', severity: 'success', summary: 'Association Tool', detail: `Found the Shipment with code "${scannedValue}" successfully!` });
                }
                else {
                    this.messageService.clear('MAIN_TOAST');
                    this.messageService.add({ key: 'MAIN_TOAST', severity: 'error', summary: 'Association Tool', detail: `Could not find any Tracker or Shipment with code "${scannedValue}"!` });
                }
            }
        }
        catch (error) {
            this.messageService.clear('MAIN_TOAST');
            this.messageService.add({ key: 'MAIN_TOAST', severity: 'error', summary: 'Association Tool', detail: error.message });
        }
        finally {
            this.scannerEntry = '';
        }
    }

    arrangeProject() {

        if(this.shipmentObj && this.trackerObj) {

            const shipmentProjectId = this.shipmentObj.projectId;

            if(shipmentProjectId) {

                const trackerProjectId = this.trackerObj.projectId;

                if(trackerProjectId && (trackerProjectId === shipmentProjectId)) {

                    const selectedProject = this.projects.find(project => project.id === trackerProjectId);

                    if(selectedProject) {
                        this.associateTrackerForm.controls['project'].setValue(selectedProject);
                        this.associateTrackerForm.controls['project'].disable();
                    }
                    else {
                        this.associateTrackerForm.controls['project'].setValue(null);
                        this.associateTrackerForm.controls['project'].disable();

                        throw new Error('You are not authorized with the Project associated with this Tracker!');
                    }
                }
                else {
                    this.associateTrackerForm.controls['project'].setValue(null);
                    this.associateTrackerForm.controls['project'].disable();

                    throw new Error('Tracker and Shipment must belong to the same project!');
                }
            }
            else {

                const trackerProjectId = this.trackerObj.projectId;

                if(trackerProjectId) {

                    const selectedProject = this.projects.find(project => project.id === trackerProjectId);

                    if(selectedProject) {
                        this.associateTrackerForm.controls['project'].setValue(selectedProject);
                        this.associateTrackerForm.controls['project'].disable();
                    }
                    else {
                        this.associateTrackerForm.controls['project'].setValue(null);
                        this.associateTrackerForm.controls['project'].disable();

                        throw new Error('You are not authorized with the Project associated with this Tracker!');
                    }
                }
                else {
                    this.associateTrackerForm.controls['project'].setValue(null);
                    this.associateTrackerForm.controls['project'].enable();
                }
            }
        }
    }

    associateTracker() {

        this.submitted = true;
        this.messageService.clear();

        if(this.associateTrackerForm.invalid) {
            return;
        }

        const associationJson = {
            trackerQRCode: this.f.trackerQRCode.value,
            deliveryNoteCode: this.f.deliveryNoteCode.value,
            projectId: this.f.project.value.id
        };

        this.trackerService.associateTracker(associationJson).subscribe(
            () => {
                this.associateTrackerForm.controls['trackerQRCode'].setValue('');
                this.removeFormErrors(this.associateTrackerForm.controls['trackerQRCode'], 'required');
                
                this.associateTrackerForm.controls['deliveryNoteCode'].setValue('');
                this.removeFormErrors(this.associateTrackerForm.controls['deliveryNoteCode'], 'required');

                this.associateTrackerForm.controls['project'].setValue(null);
                this.associateTrackerForm.controls['project'].disable();
                this.removeFormErrors(this.associateTrackerForm.controls['project'], 'required');
        
                this.messageService.clear('MAIN_TOAST');
                this.messageService.add({ key: 'MAIN_TOAST', severity: 'success', summary: 'Association Tool', detail: `Tracker "${associationJson.trackerQRCode}" associated with Delivery Note "${associationJson.deliveryNoteCode}" successfully!` });
            },
            (error: any) => {
                this.messageService.clear('MAIN_TOAST');
                this.messageService.add({ key: 'MAIN_TOAST', severity: 'error', summary: 'Association Tool', detail: error.message });
            }
        );
    }

    removeFormErrors = (control: AbstractControl, error: string) => {

        const err = control.errors; // get control errors

        console.log(err);

        if (err) {

            delete err[error]; // delete your own error

            if (!Object.keys(err).length) { // if no errors left
                control.setErrors(null); // set control errors to null making it VALID
            }
            else {
                control.setErrors(err); // controls got other errors so set them back
            }
        }
    }
}
