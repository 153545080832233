import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';

import * as jwt_decode from 'jwt-decode';

import { ApiService } from '../shared/services/api.base.service';

import { User } from './user';

/* ----------- * - * ----------- */

export var authUserTempKey: string = '';
export var authUserLanguageKey: string = '';
export var authUserTimezoneKey: string = '';

const setAuthUserTempKey = (key: string) => { authUserTempKey = key; }
const setAuthUserLanguageKey = (key: string) => { authUserLanguageKey = key; }
const setAuthUserTimezoneKey = (key: string) => { authUserTimezoneKey = key; }

const STORAGE_KEYS = {
    CURRENT_USER: 'currentUser',
    REMEMBER_ME: 'REMEMBER_ME'
}

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private sessionsBaseUrl = "sessions";
    private forgotPasswordURL = "forgotPassword";
    private resetPasswordURL = "resetPassword";
    private intervalTokenExpiredCheck: any;

    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    private _session_version: number = new Date().getTime();
    public listenerSessionVersion: BehaviorSubject<number>;

    constructor(
        private api: ApiService,
        private router: Router
    ) {
        const localStorageUser = this.getUserFromLocalStorage();
        this.currentUserSubject = new BehaviorSubject<User>(localStorageUser);
        this.listenerSessionVersion = new BehaviorSubject<number>(this.session_version);
        this.currentUser = this.currentUserSubject.asObservable();
        this.setCurrentUserToLocalStorage(localStorageUser, true);
        this.checkIsTokenExpired();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    public get session_version(): number {
        return this._session_version;
    }

    login(username: string, password: string) {
        return this.api.httpPOST<any>(this.sessionsBaseUrl, { username, password })
            .pipe(
                map(user => {
                    // login successful if there's a jwt token in the response
                    if (user && user.token) {
                        const tokenInfo = jwt_decode(user.token);
                        const returnUser: User = {
                            username: tokenInfo.user.username,
                            firstname: tokenInfo.user.firstname,
                            lastname: tokenInfo.user.lastname,
                            clientId: tokenInfo.user.clientId,
                            clientName: tokenInfo.user.clientName,
                            clientType: tokenInfo.user.clientType,
                            temperature: tokenInfo.user.temperature,
                            language: tokenInfo.user.language,
                            timezone: tokenInfo.user.timezone,
                            token: user.token,
                            userTypes: tokenInfo.user.userTypes,
                            tokenValidUntil: (tokenInfo.exp) * 1000
                        };
                        // store user details and jwt token in local storage to keep user logged in between page refreshes
                        this.setCurrentUserToLocalStorage(returnUser);
                    }

                    return user;
                })
            );
    }

    private getUserFromLocalStorage() {
        try {
            const localStorageUser = JSON.parse(localStorage.getItem(STORAGE_KEYS.CURRENT_USER));
            return localStorageUser;
        } catch (error) {
            return null;
        }
    }

    private setCurrentUserToLocalStorage(user: User, isConstructer: boolean = false) {
        setAuthUserTempKey(user ? user.temperature : null);
        setAuthUserLanguageKey(user ? user.language : null);
        setAuthUserTimezoneKey(user ? user.timezone : null);

        this.buildIntervalTokenCheck();

        if (!isConstructer) {
            localStorage.setItem(STORAGE_KEYS.CURRENT_USER, JSON.stringify(user));
            this.currentUserSubject.next(user);
        }
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem(STORAGE_KEYS.CURRENT_USER);
        this.currentUserSubject.next(null);
        this.breakIntervalTokenCheck();
        this.router.navigate(['login']);
    }

    changeSessionVersion() {
        this._session_version = new Date().getTime();
        this.listenerSessionVersion.next(this.session_version);
    }

    updatedAuthUserProfile(firstname: string, lastname: string, timezone: string, temperature: string, language: string) {
        try {
            const latestUser = {
                ...this.currentUserValue,
                firstname,
                lastname,
                timezone,
                temperature,
                language
            };

            this.setCurrentUserToLocalStorage(latestUser);
        } catch (error) {

        }
    }

    buildIntervalTokenCheck = () => {
        this.breakIntervalTokenCheck();
        setInterval(() => this.checkIsTokenExpired(), 10000);
    }
    breakIntervalTokenCheck = () => {
        if (this.intervalTokenExpiredCheck)
            clearInterval(this.intervalTokenExpiredCheck);
    }
    checkIsTokenExpired = () => {
        try {
            const localStorageUser = this.getUserFromLocalStorage();
            if (!localStorageUser)
                return;

            if (new Date().getTime() > localStorageUser.tokenValidUntil)
                this.logout();
        } catch (error) {
            this.logout();
        }
    }

    saveLogedInUserName(userName: string) {
        localStorage.setItem(STORAGE_KEYS.REMEMBER_ME, userName)
    }
    getLoggedInUserName(): string | null {
        return localStorage.getItem(STORAGE_KEYS.REMEMBER_ME)
    }
    removeLoggedInUserName() {
        localStorage.removeItem(STORAGE_KEYS.REMEMBER_ME);
    }

    forgotPassword(requestBody: any) {
        return this.api.httpPOST<any>(this.forgotPasswordURL, requestBody);
    }

    resetPassword(requestBody: any) {
        return this.api.httpPOST<any>(this.resetPasswordURL, requestBody);
    }
}


export const USER_TYPES = {
    SUPER_ADMIN: 'superadmin',
    ADMIN: 'admin',
    VIEWER: 'view',
    DEPLOYER: 'deployer',
    UPLOADER: 'uploader',
    REPORTER: 'reporter',
    ANALYZER: 'analyzer'
}

export const CLIENT_TYPES = {
    ALL_ACCESS: 0,
    ASSET_TRACKING: 1,
    COLD_CHAIN: 2,
    SHIPMENT: 3,
    BRACELET: 4
}