import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

import { Observable } from 'rxjs';

import { AutotestService } from './autotest.service';

@Injectable({
    providedIn: 'root'
})
export class AutotestsResolver implements Resolve<any[]> {

    constructor(private serviceAutotest: AutotestService) { }

    resolve(): Observable<any[]> {
        return this.serviceAutotest.listAutotests();
    }
}

@Injectable({
    providedIn: 'root'
})
export class AutotestResolver implements Resolve<any> {

    constructor(private serviceAutotest: AutotestService) { }

    resolve(route: ActivatedRouteSnapshot, ): Observable<any> {
        const autotestId = route.paramMap.get('autotestId');
        if (isNaN(+autotestId)) {
            const message = `autotestId was not a number: ${autotestId}`;
            console.error(message);
            return Observable.throw(message);
        }

        return this.serviceAutotest.showAutotest(autotestId);
    }
}
