import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import md5 from 'md5';
import { MessageService } from 'primeng/api';

import { AuthenticationService } from '../../authorization/authentication.service';
import { validatorPasswordFNC } from '../../shared/custom-form-validators';

@Component({
  selector: 'reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  resetPasswordForm: FormGroup;
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authenticationService: AuthenticationService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.resetPasswordForm = this.formBuilder.group({
      password: ['', [Validators.required, validatorPasswordFNC()]],
      confirmPassword: ['', Validators.required]
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.resetPasswordForm.controls; }

  ResetPassword() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.resetPasswordForm.invalid) {
      return;
    }

    let resetPasswordToken;
    const paramsStr = location.search.substr(1); //TO REMOVE HEADING ?
    const keyValueTokens = paramsStr.split('=');

    if(keyValueTokens[0] === 'token') {
      resetPasswordToken = keyValueTokens[1];
    }
    else {
        this.messageService.clear('MAIN_TOAST');
        this.messageService.add({ key: 'MAIN_TOAST', severity: 'error', summary: 'Reset Password', detail: 'Password reset token not found' });
        return;
    }

    const reqBody = {
      resetPasswordToken: resetPasswordToken,
      password: md5(this.resetPasswordForm.value.password)
    };

    this.authenticationService.resetPassword(reqBody)
      .subscribe(
        () => {
          this.messageService.clear('MAIN_TOAST');
          this.messageService.add({ key: 'MAIN_TOAST', severity: 'success', summary: 'Reset Password', detail: "You have successfully reset your password. Now, you can login with your new credentials." });

          this.router.navigate(['login']);
        },
        error => {
            this.messageService.clear('MAIN_TOAST');
            this.messageService.add({ key: 'MAIN_TOAST', severity: 'error', summary: 'Reset Password', detail: error.message });
        });
  }

}
