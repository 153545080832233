import {NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {ClickOutsideModule} from 'ng-click-outside';

import {AgmCoreModule} from '@agm/core';
import {AgmJsMarkerClustererModule} from '@agm/js-marker-clusterer';

import {TableModule} from 'primeng/table';
import {ColorPickerModule} from 'primeng/colorpicker';
import {InputTextModule} from 'primeng/inputtext';
import {DialogModule} from 'primeng/dialog';

import {LeftMenuComponent} from './components/left-menu/left-menu.component';
import {NavBarComponent} from './components/nav-bar/nav-bar.component';
import {UserAvatar} from './components/avatar-image/avatar-image.component';
import {AuthUserAvatarAC} from './components/ac-auth-user-avatar/ac-auth-user-avatar.component';
import {AssetImage} from './components/asset-image/asset-image.component';
import {SearchBox} from './components/search-box/search-box.component';
import {SmallButton} from './components/small-button/small-button.component';
import {CardLabel} from './components/card-label/card-label.component';
import {CustomMapsAC} from './components/ac-custom-maps/ac-custom-maps.component';
import {MapAutoFitToggleBtnAC} from './components/ac-map-autofit-btn/ac-map-autofit-toggle-btn.component';
import {TabRouteAC} from './components/ac-tab-route/ac-tab-route.component';
import {CustomToaster} from './components/custom-toaster/custom-toaster.component';
import {ACAlarmDropdown} from './components/alarms-dropdown/alarms-dropdown.component';
import {TrackerAlarmItemAC} from './components/tracker-alarm-item/tracker-alarm-item.component';

import {SearchPipe} from './pipes/search.pipe';
import {TemperaturePipe} from './pipes/ac-temperature.pipe';
import {BatteryPipe} from './pipes/ac-battery.pipe';
import {FullDatePipeAC, OnlyDatePipeAC, OnlyTimePipeAC} from './pipes/ac-date-pipes';

import {
  DirectiveSuperAdminDirectiveAC,
  DirectiveAdminDirectiveAC,
  DirectiveViewerDirectiveAC,
  DirectiveAllAccessDirectiveAC,
  DirectiveAssetTrackingDirectiveAC,
  DirectiveShipmentDirectiveAC
} from '../shared/directives/permissions';

import {AssetsService} from './services/asset.service';
import {AssetResolver, AssetsResolver} from './services/asset-resolver.service';
import {BeaconsService} from './services/beacon.service';
import {BeaconResolver, BeaconsResolver} from './services/beacon-resolver.service';
import {TrackerService} from './services/tracker.service';
import {
  TrackersResolver,
  TrackerBriefResolver,
  TrackerTemperatureResolver,
  TrackerParametersResolver,
  TrackerHumidityResolver
} from './services/tracker-resolver.service';
import {ProjectService} from './services/project.service';
import {ProjectResolver, ProjectsResolver} from './services/project-resolver.service';
import {PeoplesService} from './services/peoples.service';
import {PeopleResolver, PeoplesResolver} from './services/peoples-resolver.service';

import { ContactService } from './services/contact.service';
import { ContactsResolver } from './services/contact-resolvers.service';
import {GeofenceResolver, GeofencesResolver} from './services/geofence-resolver.service';
import {GeofenceService} from './services/geofence.service';
import {GeocodeService} from './services/geocode.service';
import {AlarmService} from './services/alarm.service';
import {UserService} from './services/user.service';
import {UsersResolver, ClientUsersResolver} from './services/users-resolvers.service';
import {ClientService} from './services/client.service';
import {ClientsResolver} from './services/client-resolvers.service';
import {ProfileService} from './services/profile.service';
import {ProfileResolver} from './services/profile-resolver.service';
import {DashboardService} from './services/dashboard.service';
import {OrderService} from './services/order.service';
import {OrdersResolver} from './services/order-resolvers.service';
import {ShipmentService} from './services/shipment.service';
import {ShipmentsResolver} from './services/shipment-resolvers.service';
import {TranslateModule} from '@ngx-translate/core';
import {AutotestService} from './services/autotest.service';
import {AutotestsResolver} from './services/autotest-resolvers.service';

/*Register the Local data value for Date support in Spanish*/
import { registerLocaleData } from '@angular/common';
import localeES from '@angular/common/locales/es';
import {CustomMapsAC1} from "./components/ac-custom-maps-shipment-order/ac-custom-maps-shipment-order.component";
registerLocaleData(localeES, 'es');

@NgModule({
    declarations: [
        LeftMenuComponent,
        NavBarComponent,
        UserAvatar,
        AuthUserAvatarAC,
        AssetImage,
        SearchBox,
        SmallButton,
        CardLabel,
        CustomMapsAC,
        MapAutoFitToggleBtnAC,
        TabRouteAC,
        CustomToaster,
        ACAlarmDropdown,
        TrackerAlarmItemAC,

        SearchPipe,
        TemperaturePipe,
        BatteryPipe,
        FullDatePipeAC,
        OnlyDatePipeAC,
        OnlyTimePipeAC,

        DirectiveSuperAdminDirectiveAC,
        DirectiveAdminDirectiveAC,
        DirectiveViewerDirectiveAC,

        DirectiveAllAccessDirectiveAC,
        DirectiveAssetTrackingDirectiveAC,
        DirectiveShipmentDirectiveAC,
        CustomMapsAC1
    ],
    imports: [
        CommonModule,
        FormsModule,
        TableModule,
        RouterModule,
        InputTextModule,
        ColorPickerModule,
        DialogModule,
        ClickOutsideModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyCYzM8iJI3UAG_n3mMg4iJAZGE6HiI3sK4',
            language: 'en',
            libraries: ['geometry', 'places', 'visualization']
        }),
        AgmJsMarkerClustererModule,
        TranslateModule
    ],
  providers: [
    AssetsService, AssetResolver, AssetsResolver, BeaconsService, BeaconResolver, BeaconsResolver,
    TrackerService, TrackersResolver, TrackerBriefResolver, TrackerTemperatureResolver, TrackerParametersResolver, TrackerHumidityResolver,
    ProjectService, ProjectResolver, ProjectsResolver,
    PeoplesService, PeopleResolver, PeoplesResolver,
    ContactService, ContactsResolver,
    GeofenceResolver, GeofencesResolver, GeofenceService,
    AlarmService,
    GeocodeService,
    UserService, UsersResolver, ClientUsersResolver,
    ClientService, ClientsResolver,
    ProfileService, ProfileResolver,
    DashboardService,
    OrderService, OrdersResolver,
    ShipmentService, ShipmentsResolver,
    AutotestService, AutotestsResolver
  ],
    exports: [
        LeftMenuComponent,
        NavBarComponent,
        UserAvatar,
        AuthUserAvatarAC,
        AssetImage,
        SearchBox,
        SmallButton,
        CardLabel,
        CustomMapsAC,
        MapAutoFitToggleBtnAC,
        TabRouteAC,
        CustomToaster,
        ACAlarmDropdown,
        TrackerAlarmItemAC,
        FormsModule,
        TableModule,
        CommonModule,
        InputTextModule,
        ColorPickerModule,
        DialogModule,
        ClickOutsideModule,

        SearchPipe,
        TemperaturePipe,
        BatteryPipe,
        FullDatePipeAC,
        OnlyDatePipeAC,
        OnlyTimePipeAC,

        DirectiveSuperAdminDirectiveAC,
        DirectiveAdminDirectiveAC,
        DirectiveViewerDirectiveAC,

        DirectiveAllAccessDirectiveAC,
        DirectiveAssetTrackingDirectiveAC,
        DirectiveShipmentDirectiveAC,
        CustomMapsAC1
    ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class SharedModule {
}
