import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { ApiService } from './api.base.service';

import { Geofence } from '../models/geofence';

@Injectable({ providedIn: 'root' })
export class GeofenceService {
  private geofencesBaseURL = "geofences";
  private saveBaseURL = "saveGeofence";
  private deleteGeofenceURL = "deleteGeofence/";

  constructor(private api: ApiService) {
  }

  getGeofences(): Observable<Geofence[]> {
    return this.api.httpGET<any[]>(this.geofencesBaseURL, 'geofences');
  }

  getGeofence(geofenceId: number): Observable<Geofence> {
    if (geofenceId === 0) {
      return of(this.newGeofence());
    }

    return this.api.httpGET<any>(this.geofencesBaseURL + "/" + geofenceId, 'geofence');
  }

  newGeofence(): Geofence {
    return {
      id: 0,
      name: "",
      description: "",
      latitude: 0,
      longitude: 0,
      parentId: 0,
      parentType: "",
      radius: 0
    };
  }

  saveGeofence(geofence: Geofence): Observable<Geofence> {
    return this.api.httpPOST<any>(this.saveBaseURL, geofence);
  }

  deleteGeofence(geofenceId) {
    return this.api.httpDELETE<any>(`${this.deleteGeofenceURL}${geofenceId}`, 'project');
  }
}