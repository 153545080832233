import {Pipe, PipeTransform} from '@angular/core';

import {convertBatteryLevelToPercentageWithIconFNC} from '../base-functions';

import {TranslateService} from '@ngx-translate/core';

@Pipe({name: 'batteryPIPE'})
export class BatteryPipe implements PipeTransform {

  constructor(private translateService: TranslateService) {
  }

  transform(value?: number): any {
    const values = convertBatteryLevelToPercentageWithIconFNC(value);
    /*Set the condition for localization.*/
    if (values == 'Charging') {
      return this.translateService.instant('Charging');
    } else if (values == 'Fully Charged') {
      return this.translateService.instant('Fully Charged');
    } else {
      return convertBatteryLevelToPercentageWithIconFNC(value);
    }

  }
}
