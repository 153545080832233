import { Observable } from 'rxjs';

declare var window: any;

export const GET_CurrentPosition = (): Observable<any> => {
    return Observable.create(async (observer) => {
        try {
            await getPositionPermission();
        } catch (error) {

        }

        try {
            const result = await getCurrentPosition();
            observer.next(result);
            observer.complete();
        } catch (error) {
            observer.next({
                latitude: 40.755751,
                longitude: -73.986446
            });
            observer.complete();
        }
    });
}

const getPositionPermission = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = window.navigator.permissions.query({ name: 'geolocation' });
            resolve();
        } catch (error) {
            reject();
        }
    })
}

const getCurrentPosition = () => {
    return new Promise(async (resolve, reject) => {
        window.navigator.geolocation.getCurrentPosition(
            res => resolve(res.coords),
            err => reject(err),
            {
                timeout: 9000
            });
    })
}