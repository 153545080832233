import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { User } from '../../authorization/user';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class RegistrationService {
    private baseUrl = environment.baseUrl + "userCreate";

    constructor(private http: HttpClient) {}

    register(name: string, last_name: string, email: string, password: string) {
        return this.http.post<any>(this.baseUrl, { name, last_name, email, password });
    }
}