export class Utils{
    public static convertStringToDate(s: string): Date{
        if (!s)
            return null;
        //2019-05-13T10:36:20.000Z
        let year = +s.substring(0,4);
        let month = +s.substring(5,7);
        let day = +s.substring(8,10);
        let hour = +s.substring(11,13);
        let min = +s.substring(14,16);
        let sec = +s.substring(17,19);
        let ms = +s.substring(20,23);

        return new Date(year, month - 1, day, hour, min, sec, ms);
    }
}